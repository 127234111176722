import React, { useState } from 'react';
import { IoAddOutline } from "react-icons/io5";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, FormGroup, Label } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { setLayoutData, updateTemplateLayout } from "../../../../../store/templateEditorData";
import CustomCropperModel from "../ImageCropper/CropperModel";
import Compressor from 'compressorjs';
import client from "../../../../../Api/HTTPClient";
import api from "../../../../../constant/apilist";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";


const ImageComponent = ({ image, layoutInfo, isEdit }) => {

  const { content } = layoutInfo;

  const dispatch = useDispatch();
  const { templateLayoutData, selectedElementData } = useSelector((state) => state.emailTemplateEditorData);

  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState("fileManager");
  const [imageSrc, setImageSrc] = useState(null);
  const [altText, setAltText] = useState("");
  const [selectedImg, setSelectedImg] = useState(null);
  const [filec, setFile] = useState(null);
  const [rawImageData, setRawImageData] = useState(null);
  const [mainImageLoader, setMainImageLoader] = useState(false);

  const toggleModal = () => { setModalOpen(!modalOpen) };

  // const handleInsertImage = () => {
  //   if (selectedImg) {
  //     const newImageList = layoutInfo.content.map((img) => img.id === selectedImg?.id ? { ...img, src: imageSrc, alt: altText } : img);
  //     let setData = { ...layoutInfo, content: newImageList };

  //     let updatedLayout = JSON.parse(JSON.stringify(templateLayoutData));
  //     let layoutIndex = updatedLayout.findIndex(currLayout => currLayout.type === setData.type);

  //     if (layoutIndex !== -1) {
  //       updatedLayout[layoutIndex] = setData;
  //       dispatch(setLayoutData(updatedLayout));
  //     }
  //   }
  //   setImageSrc(null);
  //   toggleModal();
  // }

  const handleInsertImage = () => {
    if (selected == "fileManager" && rawImageData) {
      setMainImageLoader(true);
      let formDataGen = new FormData();
      formDataGen.append("image_name", selectedImg.id);
      formDataGen.append("file", rawImageData);
      const config = {
        headers: {
          "x-access-token": "ipay-access-token",
          authorization: localStorage.getItem("token"),
        }
      };

      axios.post(`${client.url(api.addEmaiTemplateImage)}`, formDataGen, config)
        .then((response) => {
          // console.log(response, "response");
          setMainImageLoader(false);
          if (response?.data) {

            const newImageList = content.map((img) => img.id === selectedImg?.id ? { ...img, src: response?.data.result, alt: altText } : img);
            let setData = { ...layoutInfo, content: newImageList };
            dispatch(updateTemplateLayout({ data: setData }));
            setImageSrc(null);
            setRawImageData(null);
            toggleModal();
          } else {
            toast.error("File upload error");
          }
        }).catch((error) => {
          setMainImageLoader(false);
        });
    } else {
      const newImageList = content.map((img) => img.id === selectedImg?.id ? { ...img, src: imageSrc, alt: altText } : img);
      let setData = { ...layoutInfo, content: newImageList };
      dispatch(updateTemplateLayout({ data: setData }));
      setImageSrc(null);
      setRawImageData(null);
      toggleModal();
    }
  }

  const handleImageChange = (event) => {

    let files = event.target?.files;
    var mimeType = files[0]?.type;

    if (files) {
      if (mimeType?.includes("jpeg") || mimeType?.includes("png")) {
        setFile(files[0]);
        toggleModal();

      } else {
        console.log("Invalid file format");
        toast.error("invalid file format. only JPG and PNG allowed");

      }
    }
  };

  const handleFile = (e) => {
    try {
      new Compressor(e, {
        quality: 0.7,
        maxWidth: 1920,
        maxHeight: 1920,
        convertSize: 500000,
        async success(result) {
          const toBase64 = result => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(result);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
          });
          setRawImageData(result);
          const base64Image = await toBase64(result)
          setImageSrc(base64Image);
          toggleModal();
        },
        error(err) {
          console.log(err.message)
        }
      })
      setFile(null)
    } catch (err) {
      console.log(err);
      setFile(null)
    }
  }

  return (
    <>
      <div style={{ position: "relative" }}
        onClick={() => {
          if (!isEdit) return false;
          setSelectedImg(image)
        }}>
        <img style={image.style} src={image.src} alt='' />
        {selectedImg?.id === image.id && (
          <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            borderRadius: "50%",
            backgroundColor: "#000000",
            height: "60px",
            width: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: "0.8",
            cursor: "pointer"
          }}
            onClick={(e) => {
              e.stopPropagation();
              toggleModal();
            }}
          >
            <IoAddOutline style={{ fontSize: "40px", color: "#ffffff" }} />
          </div>
        )}
      </div>
      <style>{`.modal-title{font-weight:600}`}</style>
      <Modal centered isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader style={{ borderBottom: "none" }}>Image Selection</ModalHeader>
        <ModalBody style={{ paddingTop: "0rem" }}>
          <div className="d-flex align-items-center space-x-4">
            <label className="d-flex align-items-center space-x-2 cursor-pointer">
              <input
                type="radio"
                name="imageOption"
                value="fileManager"
                checked={selected === "fileManager"}
                onChange={(e) => setSelected(e.target.value)}
                className="hidden"
              />
              <span className="text-gray-700 ml-2" style={{ fontSize: "15px" }}>File Manager</span>
            </label>

            <label className="d-flex align-items-center space-x-2 cursor-pointer ml-4">
              <input
                type="radio"
                name="imageOption"
                value="addImageUrl"
                checked={selected === "addImageUrl"}
                onChange={(e) => setSelected(e.target.value)}
                className="hidden"
              />
              <span className="text-gray-700 ml-2" style={{ fontSize: "15px" }}>Add Image URL</span>
            </label>
          </div>

          {selected === "fileManager" && (
            <div className="mt-3" style={{ width: "100%" }}>
              {/* Upload Button */}
              <button
                className="btn"
                onClick={() => document.getElementById("fileInput").click()}
                style={{ marginBottom: "10px", backgroundColor: "#008fe2", color: "#fff" }}
              >
                Upload From Computer
              </button>
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />

              {/* Image Preview Box */}
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  border: "2px dashed #ccc",
                  backgroundColor: "#fffde6",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                {imageSrc ? (
                  <img src={imageSrc} alt="Uploaded" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                ) : (
                  <span style={{ color: "#888" }}>No image selected</span>
                )}
              </div>

              {/* Alternative Text Input */}
              <div className="d-flex align-items-center" style={{ paddingTop: "5px" }}>
                <label
                  className="mb-0"
                  style={{
                    marginRight: "15px",
                    lineHeight: "38px",
                    fontSize: "16px"
                  }}
                >
                  Alternative Text
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter alternative text here..."
                  value={altText}
                  onChange={(e) => setAltText(e.target.value)}
                  style={{
                    flex: 1,
                    border: "1px solid gainsboro",
                    outline: "none",
                    padding: "5px",
                    fontSize: "16px",
                    boxShadow: "none"
                  }}
                />
              </div>
            </div>
          )}
          {selected === "addImageUrl" && <>
            <div className="d-flex align-items-center my-3" style={{ paddingTop: "5px" }}>
              <label
                className="mb-0"
                style={{
                  marginRight: "15px",
                  lineHeight: "38px",
                  fontSize: "15px"
                }}
              >
                Add Image URL
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="https://www.example.com/sample.png"
                value={imageSrc}
                onChange={(e) => setImageSrc(e.target.value)}
                style={{
                  flex: 1,
                  border: "1px solid gainsboro",
                  outline: "none",
                  padding: "5px",
                  fontSize: "15px",
                  boxShadow: "none"
                }}
              />
            </div>
            <div className="d-flex align-items-center" style={{ paddingTop: "5px" }}>
              <label
                className="mb-0"
                style={{
                  marginRight: "15px",
                  lineHeight: "38px",
                  fontSize: "15px"
                }}
              >
                Alternative Text
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter alternative text here..."
                value={altText}
                onChange={(e) => setAltText(e.target.value)}
                style={{
                  flex: 1,
                  border: "1px solid gainsboro",
                  outline: "none",
                  padding: "5px",
                  fontSize: "15px",
                  boxShadow: "none"
                }}
              />
            </div>
          </>}

        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleInsertImage} disabled={!imageSrc}>
            Insert
          </Button>
        </ModalFooter>
      </Modal>
      <CustomCropperModel
        file={filec}
        onCompleted={(croppedFile) => {
          if (croppedFile) {
            handleFile(croppedFile)
          }
        }}
        onDiscard={() => {
          setFile(null);
        }}
      />
    </>
  )
};

const TextComponent = ({ text, layoutInfo }) => {

  const dispatch = useDispatch();
  const { templateLayoutData, selectedElementData } = useSelector((state) => state.emailTemplateEditorData);

  const handleInput = (e) => {
    const textData = e.target.textContent;

    const dataList = layoutInfo.content.map((data) => data.id === text?.id ? { ...data, content: textData } : data);
    let setData = { ...layoutInfo, content: dataList };

    let updatedLayout = JSON.parse(JSON.stringify(templateLayoutData));
    let layoutIndex = updatedLayout.findIndex(currLayout => currLayout.type === setData.type);

    if (layoutIndex !== -1) {
      updatedLayout[layoutIndex] = setData;
      dispatch(setLayoutData(updatedLayout));
    }
  };

  return (
    <div
      contentEditable
      suppressContentEditableWarning={true}
      onBlur={(event) => { handleInput(event) }}
      style={text.style}
    >
      {text.content}
    </div>
  )
};

export const ImageAndTextElement = ({ elemContent, isEdit }) => {

  const { content } = elemContent;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${content.length}, ${100 / content.length}%)`,
        gap: "2px"
      }}
      className='mb-0'
    >
      {content.map((element, index) => (
        element.type === "image" ? (
          <ImageComponent key={index} image={element} layoutInfo={elemContent} isEdit={isEdit} />
        ) : (
          <TextComponent key={index} text={element} layoutInfo={elemContent} />
        )
      ))}
    </div>
  )
};
