import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';

import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import { Link as NavLink } from "react-router-dom";
import useForm from 'react-hook-form'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem } from 'reactstrap';
import { DollarSign, MapPin, X, TrendingDown, ArrowUp, ShoppingCart, Search, Activity, User, Users, Book } from 'react-feather';
import Slider from 'react-slick';
import CountUp from 'react-countup';
import RightBar from '../../components/social-app/rightBar';
var Knob = require('knob')// browserify require
var primary = localStorage.getItem('primary_color') || '#4466f2';


function Speed_mail_component() {
  const [modal2, setModal2] = useState();

  const [delete_modal, set_delete_toggle] = useState();
  const [del_templ_id, set_del_templ_id] = useState("");
  const [access_level, setAccessLevel] = useState(localStorage.getItem("accessLevel"))



  let { del_temp_id } = '';

  const [templ_name, set_templ_Name] = useState("");

  const [templ_user_type, set_templ_user_type] = useState("");
  const [templ_test_mail, set_templ_test_mail] = useState("");
  const [templ_from, set_templ_from] = useState("");
  const [templ_subject, set_templ_subject] = useState("");
  const [templ_layout, set_templ_layout] = useState("");
  const [templ_comments, set_templ_comments] = useState("");
  const [activeTab, setActiveTab] = useState('1');


  let [template_data, set_template_data] = useState(null);

  const toggle2 = () => {
    setModal2(!modal2)
  }


  const delete_toggle = () => {
    set_delete_toggle(!delete_modal)
  }

  const set_del_id = (temp_id) => {
    console.log(temp_id);
    set_del_templ_id(temp_id)
  }




  /* set_del_templ_id(del_temp_id); */




  const [settings, setSettings] = useState({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // variableWidth:true,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });

  const { errors } = useForm(); // initialise the hook
  const [loadingbtn, setloadingbtn] = useState(false);
  const [loading, setloading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateChangeData, setTemplateChangeData] = useState({})

  const onSubmit = data => {
    if (data !== '') {
      //let data = {templ_name : templ_name};
      // console.log(data);
      //   console.log(JSON.stringify(data));
      setloadingbtn(true);
      client.post(api.add_new_mail_template, data, async function (error, response) {
        if (!error) {
          // alert(JSON.stringify(response));
          //   return false;
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {

                toast.success("Updated Successfully");
                // setcashbackdata(response.result.data);
              } else {
                toast.error(response.message);
              }
            } catch (e) {
              toast.error("Internal error occured. Please contact support");
            }
          } else {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }
        setloadingbtn(false);
      });
    } else {
      errors.showMessages();
    }
  };

  const changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  const handletempl_nameChange = (e) => {
    this.setState({ templ_name: e.target.value });
    //console.log(templ_name);
  }

  const toggle = (tab) => {


  }

  const handleSubmit = (e, data) => {

    e.preventDefault();
    //alert(`Submitting Name ${templ_name}`)
    data = {
      templ_name: templ_name, templ_user_type: templ_user_type, templ_test_mail: templ_test_mail, templ_from:
        templ_from, templ_subject: templ_subject, templ_layout: templ_layout, templ_comments: templ_comments

    };
    if (data !== '') {
      //let 
      //console.log(data);
      // console.log(JSON.stringify(data));
      setloadingbtn(true);
      client.post(api.add_new_mail_template, data, async function (error, response) {
        if (!error) {
          // alert(JSON.stringify(response));
          //   return false;
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {

                toast.success("Updated Successfully");
                toggle2();

                window.location.reload(true, 3000);

              } else {
                toast.error(response.message);
              }
            } catch (e) {
              toast.error("Internal error occured. Please contact support");
            }
          } else {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }
        setloadingbtn(false);
      });
    } else {
      errors.showMessages();
    }

  }

  const handleDeleteSubmit = (e, templ_id) => {

    //console.log(templ_id);

    e.preventDefault();
    //alert(`Submitting Name ${templ_name}`)
    let data = { template_id: templ_id };
    if (data !== '') {
      //let 

      setloadingbtn(true);
      client.post(api.delete_mail_template, data, async function (error, response) {
        if (!error) {
          // alert(JSON.stringify(response));
          //   return false;
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {

                toast.success("Template Deleted Successfully");
                delete_toggle();

                //window.location.reload(true,3000);

              } else {
                toast.error(response.message);
              }
            } catch (e) {
              toast.error("Internal error occured. Please contact support");
            }
          } else {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }
        setloadingbtn(false);
      });
    } else {
      errors.showMessages();
    }

  }



  const geta_all_templates = async () => {
    let postdata = {};
    let current = this;
    // current.setState({ loading: true });
    setloading(true);
    client.post(api.get_all_mail_templates, postdata, async function (error, response) {
      if (!error) {
        // alert(JSON.stringify(response));
        //   return false;
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              set_template_data(response.result.data);
              // current.props.navigation.navigate("Verification");
            } else {
              toast.error(response.message);
            }
          } catch (e) {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }
      } else {
        toast.error("Internal error occured. Please contact support");
        // _showToast.error("Internal error occured. Please contact support.");
      }
      // current.setState({ loading: false });
      setloading(false);

    });
  };

  useEffect(() => {
    // alert("hrer");

    geta_all_templates();
  }, []);


  const toggleupdateModal = () => {
    setUpdateModal(!updateModal)
  }

  const onUpdateTemplate = (event) => {
    event.preventDefault();
    const postdata = template_data

    client.post(api.update_many_template, postdata, async function (error, response) {
      if (!error) {
        // alert(JSON.stringify(response));
        //   return false;
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              //set_template_data(response.result.data);
              // current.props.navigation.navigate("Verification");
            } else {
              toast.error(response.message);
            }
          } catch (e) {
            toast.error("Internal error occured. Please contact support");
          }
        } else {
          toast.error("Internal error occured. Please contact support");
        }
      } else {
        toast.error("Internal error occured. Please contact support");
        // _showToast.error("Internal error occured. Please contact support.");
      }
      // current.setState({ loading: false });
      setloading(false);

    });

  }

  const renderUpdateModal = () => {
    return (
      <Modal isOpen={updateModal} toggle={toggleupdateModal} className="modal-body" centered={true}>
        <form className="theme-form" onSubmit={onUpdateTemplate}>
          <ModalHeader>Update Changes</ModalHeader>
          <ModalBody>
            <h6>Do you really want to update the changes ?</h6>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleupdateModal}>Not sure!</Button>
            <Button color="primary" type="submit"  >Yes, Please!</Button>
          </ModalFooter>
        </form>
      </Modal>

    );
  }

  const onChangeMultipleTemplate = (event, id, index) => {
    const { name, value } = event.target;
    const updatedTemplateData = [...template_data]
    const template = updatedTemplateData.find(el => {
      return el._id == id
    });
    const updatedTemplate = {
      ...template,
      [name]: value
    }
    updatedTemplateData[index] = updatedTemplate
    set_template_data(updatedTemplateData)
  }

  // console.log(templateChangeData);
  // console.log(template_data);

  const rendertable = (template_type_check) => {

    //console.log("type ="+template_type_check);
    return (
      <Fragment>
        <div className="table-responsive">
          <table className="table table-border-horizontal table-striped table-light">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Test mails</th>
                <th scope="col">From</th>
                <th scope="col">Subject</th>
                <th scope="col">Comments</th>
                <th scope="col" style={{ minWidth: "80px" }}>Action</th>

              </tr>
            </thead>
            <tbody>
              {template_data.map((item, index) => (
                template_type_check == item.templ_user_type ?
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>
                      <textarea className="form-control" rows="1" onChange={(event) => onChangeMultipleTemplate(event, item._id, index)}
                        value={item.templ_name} name="templ_name" disabled={access_level == 1 ? true : false}>
                      </textarea>
                    </td>
                    <td><textarea className="form-control" rows="1" onChange={(event) => onChangeMultipleTemplate(event, item._id, index)}
                      value={item.templ_test_mail} name="templ_test_mail" disabled={access_level == 1 ? true : false}></textarea></td>
                    <td><textarea className="form-control" rows="1" onChange={(event) => onChangeMultipleTemplate(event, item._id, index)}
                      value={item.templ_from} name="templ_from" disabled={access_level == 1 ? true : false}></textarea></td>
                    <td><textarea className="form-control" rows="1" onChange={(event) => onChangeMultipleTemplate(event, item._id, index)}
                      value={item.templ_subject} name="templ_subject" disabled={access_level == 1 ? true : false}></textarea></td>
                    <td><textarea className="form-control" rows="1" onChange={(event) => onChangeMultipleTemplate(event, item._id, index)}
                      value={item.templ_comments} name="templ_comments" disabled={access_level == 1 ? true : false}></textarea></td>
                    <td>

                      <NavLink style={{ maxWidth: "50%", padding: "1px" }} to={`/settings/mail_template_details/${item._id}`}
                        item={item}
                      >View</NavLink>
                      {access_level == 3 && <i className="btn fa fa-trash" style={{ color: "rgb(228, 86, 110)", maxWidth: "30px", float: "Right", padding: "0px" }} onClick={() => {
                        delete_toggle();
                        set_del_id(item._id);
                      }} ></i>}

                      {/** <Button color="Secondary" onClick={delete_toggle(item._id)}>Delete</Button> */}
                      {/*<Button color="Secondary" onClick={() => {
          delete_toggle();set_del_id(item._id);}} >Delete</Button> */}



                    </td>
                  </tr>
                  : null

              ))}
            </tbody>
          </table>
        </div>
        <div style={{ textAlign: "center" }}>
          <button className="btn btn-outline-primary btn-lg" style={{ padding: "5px 15px", margin: " 8px auto" }}
            onClick={toggleupdateModal}>Update</button>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className="col-lg-7 col-sm-12" style={{ fontSize: "12px" }}>
        <Breadcrumb title="Speed mails" parent="Home" />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              {renderUpdateModal()}
              <div className="card-body" style={{ paddingBottom: '0px' }}>
                <div className="row">
                  <div className="col-sm-2">
                    <div className="selling-update text-center" style={{ padding: "15px 10px", cursor: "pointer" }}>
                      <Search />
                      <h5 className="mb-0 f-14">Email Templates</h5>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="selling-update text-center" style={{ padding: "15px 10px", cursor: "pointer" }}>
                      <Users />
                      <h5 className="mb-0 f-14">SMS Templates</h5>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="selling-update text-center" style={{ padding: "15px 10px", cursor: "pointer" }}>
                      <ShoppingCart />
                      <h5 className="mb-0 f-14">Auto Reminders</h5>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="selling-update text-center" style={{ padding: "15px 10px", cursor: "pointer" }}>
                      <Book />
                      <h5 className="mb-0 f-14">History</h5>
                    </div>
                  </div>

                </div>

              </div>

              {access_level != 1 && <div className="card-body ">
                {/* <!-- Vertically centered modal--> */}
                <Button color="primary" onClick={toggle2}>Add New Template</Button>

                <Modal isOpen={modal2} toggle={toggle2} className="modal-body" centered={true}>
                  <form className="theme-form" noValidate="" onSubmit={handleSubmit}>
                    <ModalHeader toggle={toggle2}>Add New Template</ModalHeader>
                    <ModalBody>


                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label" htmlFor="business_name">User</label>
                        <div className="col-sm-9">
                          <select className="form-control" name="templ_user_type" id="user_type" value={templ_user_type}
                            onChange={e => set_templ_user_type(e.target.value)} >
                            <option value="" >Select Option</option>
                            <option value="customer" >Customer</option>
                            <option value="merchant" >Merchant</option>
                            <option value="others" >Others</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Name</label>
                        <div className="col-sm-9">
                          <input className="form-control" id="inputEmail3" type="text" placeholder="Name" name="templ_name" value={templ_name}
                            onChange={e => set_templ_Name(e.target.value)} />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label" htmlFor="inputPassword3">Test Mail</label>
                        <div className="col-sm-9">
                          <input className="form-control" id="Test_mail" type="text" placeholder="Test Mail" name="templ_test_mail" value={templ_test_mail}
                            onChange={e => set_templ_test_mail(e.target.value)} />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label" htmlFor="inputPassword3">From</label>
                        <div className="col-sm-9">
                          <input className="form-control" id="From" type="text" placeholder="From" name="templ_from" value={templ_from}
                            onChange={e => set_templ_from(e.target.value)} />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label" htmlFor="inputPassword3">Subject</label>
                        <div className="col-sm-9">
                          <input className="form-control" id="Subject" type="text" placeholder="Subject" name="templ_subject" value={templ_subject}
                            onChange={e => set_templ_subject(e.target.value)} />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label" htmlFor="inputPassword3">Layout</label>
                        <div className="col-sm-9">
                          <textarea className="form-control" id="layout" rows="2" name="templ_layout" value={templ_layout}
                            onChange={e => set_templ_layout(e.target.value)}></textarea>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Comments:</label>
                        <div className="col-sm-9">

                          <textarea className="form-control" id="comments" rows="2" name="templ_comments" value={templ_comments}
                            onChange={e => set_templ_comments(e.target.value)} ></textarea>
                        </div>
                      </div>

                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" type="submit">Save Changes</Button>
                      <Button color="secondary" onClick={toggle2}>Cancel</Button>
                    </ModalFooter>
                  </form>
                </Modal>



              </div>}


              <div>
                {/* <!-- Vertically centered modal--> */}


                <Modal isOpen={delete_modal} toggle={delete_toggle} className="modal-body" centered={true}>
                  <form className="theme-form" noValidate="" >
                    <ModalHeader toggle={delete_toggle}>Delete Template</ModalHeader>
                    <ModalBody>
                      <h4>Do you really want to delete this Template ?</h4>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" type="submit" onClick={(e) => { handleDeleteSubmit(e, del_templ_id) }} >Yes</Button>
                      <Button color="secondary" onClick={delete_toggle}>No</Button>
                    </ModalFooter>
                  </form>
                </Modal>
              </div>


            </div>
          </div>


        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          {
            <div className="col-sm-12 col-xl-12 xl-100">
              <div className="card">

                <div className="card-body">
                  <Nav tabs className="nav-pills nav-primary">

                    <NavItem className="nav nav-tabs nav-link" id="myTab" role="tablist">
                      <NavLink className={activeTab == '1' ? 'active nav-link' : ''} onClick={() => setActiveTab('1')} value='1'>
                        Customers ({template_data ? template_data.filter(function (element) {
                          return element.templ_user_type === 'customer';
                        }).length : 0})
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav nav-tabs nav-link" id="myTab" role="tablist">
                      <NavLink className={activeTab == '2' ? 'active nav-link' : ''} onClick={() => setActiveTab('2')} value='2'>
                        Merchants ({template_data ? template_data.filter(function (element) {
                          return element.templ_user_type === 'merchant';
                        }).length : 0})
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav nav-tabs nav-link" id="myTab" role="tablist">
                      <NavLink className={activeTab == '3' ? 'active nav-link' : ''} onClick={() => setActiveTab('3')} value='3'>
                        Others ({template_data ? template_data.filter(function (element) {
                          return element.templ_user_type === 'others';
                        }).length : 0})
                      </NavLink>
                    </NavItem>


                  </Nav>


                  {template_data ?

                    <TabContent activeTab={activeTab} >


                      <TabPane tabId="1">
                        {/* <p className="mb-0 m-t-30">Waiting</p> */}

                        {rendertable('customer')}



                      </TabPane>
                      <TabPane tabId="2">
                        {/*<p className="mb-0 m-t-30">Approved</p>*/}

                        {rendertable('merchant')}

                      </TabPane>

                      <TabPane tabId="3">
                        {/*<p className="mb-0 m-t-30">Unapproved</p>*/}

                        {rendertable('others')}

                      </TabPane>

                    </TabContent>
                    : null}


                </div>


              </div>
            </div>
          }


        </div>
      </div>
    </Fragment>





  );
  // }
}

export default Speed_mail_component;