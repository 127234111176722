import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    templateLayoutData: [],
    selectedElementData: null,
    tableProps: null,
    htmlData: '',
    parentCompStyle: {
        padding: "3.5rem 5rem",
        minHeight: "80vh",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F2F2F2"
    },
    childCompStyle: {
        width: "100%",
        maxWidth: "600px",
        backgroundColor: "#FFFFFF"
    }
};

const appSlice = createSlice({
    name: "emailTemplateEditorData",
    initialState,
    reducers: {
        setLayoutData: (state, action) => {
            state.templateLayoutData = action.payload;
        },
        setSelectedElement: (state, action) => {
            state.selectedElementData = action.payload;
        },
        setTableProps: (state, action) => {
            state.tableProps = action.payload;
        },
        updateTemplateLayout: (state, action) => {
            const { data } = action.payload;

            const index = state.templateLayoutData.findIndex(item => item.type.toLowerCase() === data.type.toLowerCase());
            if (index !== -1) {
                state.templateLayoutData[index] = { ...state.templateLayoutData[index], ...data };
            }
        },
        setHtmlData: (state, action) => {
            state.htmlData = action.payload;
        },
        setCompStyleData: (state, action) => {
            let getData = action.payload;
            if (getData.type === 'outercolor') {
                state.parentCompStyle = getData.data;
            }
            if (getData.type === 'innercolor') {
                state.childCompStyle = getData.data;
            }
        }
    },
});

export const {
    setLayoutData,
    setSelectedElement,
    setTableProps,
    updateTemplateLayout,
    setHtmlData,
    setCompStyleData
} = appSlice.actions;


export default appSlice.reducer;
