import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setSelectedElement, setLayoutData } from "../../../../../store/templateEditorData";

const ColumnLayoutElement = ({ elemContent }) => {
    const { numOfCol, columnData } = elemContent;
    const dispatch = useDispatch();
    const { selectedElementData, templateLayoutData } = useSelector((state) => state.emailTemplateEditorData);

    console.log(selectedElementData, "selected----");

    const handleSelectElem = (colData) => {
        console.log(colData, "colData");
        setTimeout(() => dispatch(setSelectedElement(colData)), 500);
    };

    const handleInput = (e, colData) => {
        const textData = e.target.textContent;

        const dataList = columnData.map((data) => data.id === colData?.id ? { ...data, content: textData } : data);
        let setData = { ...elemContent, columnData: dataList };

        let updatedLayout = JSON.parse(JSON.stringify(templateLayoutData));
        let layoutIndex = updatedLayout.findIndex(currLayout => currLayout.type === setData.type);

        if (layoutIndex !== -1) {
            updatedLayout[layoutIndex] = setData;
            dispatch(setLayoutData(updatedLayout));
        }
    };

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: numOfCol === 2 ? "50% 50%" : "repeat(3, 33.3%)",
                gap: "0px"
            }}
            className="mb-0"
        >
            {columnData.map((colData, index) => (
                <div
                    key={index}
                    contentEditable
                    suppressContentEditableWarning={true}
                    onClick={() => handleSelectElem(colData)}
                    style={colData.style}
                    onBlur={(event) => { handleInput(event, colData) }}
                >
                    {colData.content}
                </div>
            ))}
        </div>
    );
};

export default ColumnLayoutElement;








// import React, { useState, useEffect } from 'react';
// import { setLayoutData, setSelectedElement, setOuterData } from "../../../../../store/templateEditorData";
// import { useSelector, useDispatch } from "react-redux";


// const ColumnLayoutElement = (layoutInfo) => {
//     // console.log(layoutInfo, "layoutInfo");
//     const { numOfCol, content, columnData } = layoutInfo.elemContent;

//     const dispatch = useDispatch();

//     const { templateLayoutData, selectedElementData, outderData } = useSelector((state) => state.emailTemplateEditorData);
//     console.log(selectedElementData, "selected----");

//     const handleSelectElem = (colData) => {
//         console.log(colData, "colData");
//         dispatch(setSelectedElement(null));
//         setTimeout(() => {
//             dispatch(setSelectedElement(colData));
//         }, 500);
//     };

//     return (
//         <>
//             <div
//                 style={{
//                     display: "grid",
//                     gridTemplateColumns: numOfCol == 2 ? "50% 50%" : `33.3% 33.3% 33.3%`,
//                     gap: '0px'
//                 }}
//                 className='mb-0'
//             >
//                 {
//                     columnData.map((colData, index) => {
//                         return (
//                             <div
//                                 key={index}
//                                 contentEditable
//                                 suppressContentEditableWarning={true}
//                                 // onBlur={handleInput}
//                                 onClick={() => handleSelectElem(colData)} // ✅ FIXED
//                                 style={colData.style}
//                             >
//                                 {colData.content}
//                             </div>
//                         )
//                     })
//                 }
//             </div>
//         </>
//     )
// }

// export default ColumnLayoutElement