import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useSelector } from "react-redux";
import ColoumnElement from "../LayoutElement/ColumnElement"
import TextElement from "../LayoutElement/TextElement"
import ImageElement from "../LayoutElement/ImageElement"
import { ImageAndTextElement } from "../LayoutElement/ImageAndTextElement"
import ButtonElement from "../LayoutElement/ButtonElements"
import TableElement from "../LayoutElement/TableElement";
import { AiOutlineDesktop } from "react-icons/ai";
import { FaMobileAlt } from "react-icons/fa";

const mobile_frame = "/assets/images/email-template-icons/mobile_frame.svg";


const TemplatePreviewModal = ({ isOpen, toggle }) => {

    const { templateLayoutData, htmlData, parentCompStyle, childCompStyle } = useSelector((state) => state.emailTemplateEditorData);
    const [viewType, setViewType] = useState('desktop');
    console.log(htmlData, "htmlData");

    const getLayoutComponent = (elemContent) => {
        switch (elemContent.type) {
            case "heading1":
            case "heading2":
            case "paragraph":
                return <TextElement elemContent={elemContent} />;
            case "1image":
            case "1+1image":
            case "2+1image":
                return <ImageElement elemContent={elemContent} isEdit={false} />;
            case "10pxspace":
            case "15pxspace":
            case "20pxspace":
                return <div style={elemContent.style}><span>&nbsp;</span></div>;
            case "image+text":
            case "text+image":
            case "text+image+text":
            case "image+text+image":
                return <ImageAndTextElement elemContent={elemContent} isEdit={false} />;
            case "normalbutton":
            case "roundedbutton":
            case "ovalbutton":
                return <ButtonElement elemContent={elemContent} />;
            case "basictable":
                return <TableElement elemContent={elemContent} />
            case "2columns":
            case "3columns":
                return <ColoumnElement elemContent={elemContent} />;
            default:
                return null;
        }
    };


    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
            <ModalHeader toggle={toggle}>
                <div className="d-flex align-items-center justify-content-center" style={{ width: "720px" }}>
                    <h3 style={{ margin: "0px", fontSize: "22px" }}>Template Preview</h3>
                    <Button style={{ fontSize: "14px" }} onClick={() => { }} className="ml-auto">Send Test Email</Button>
                </div>
            </ModalHeader>
            <ModalBody style={{ padding: "0px" }}>
                <div className="h-100 w-100 position-relative">
                    <div
                        className="d-flex align-items-center rounded-pill p-2 bg-white shadow"
                        style={{ position: "absolute", top: "0.7rem", left: "0.7rem" }}
                    >
                        <div
                            className="rounded-pill"
                            style={{ border: viewType === "desktop" ? "1px solid #008BD0" : "1px solid #fff", backgroundColor: viewType === "desktop" ? "#eff7ff" : "#fff", fontSize: "13px", padding: "0.3rem 0.5rem", cursor: "pointer" }}
                            onClick={() => { setViewType('desktop') }}
                        >
                            <AiOutlineDesktop style={{ fontSize: "18px", marginRight: "0.3rem" }} /> Desktop
                        </div>
                        <div
                            className="rounded-pill"
                            style={{ border: viewType === "mobile" ? "1px solid #008BD0" : "1px solid #fff", backgroundColor: viewType === "mobile" ? "#eff7ff" : "#fff", fontSize: "13px", padding: "0.3rem 0.5rem", cursor: "pointer" }}
                            onClick={() => { setViewType('mobile') }}
                        >
                            <FaMobileAlt style={{ fontSize: "18px", marginRight: "0.3rem" }} /> Mobile
                        </div>

                    </div>
                    {
                        viewType == "desktop" ?
                            <div
                                className=""
                                id="canvas-data"
                                style={parentCompStyle}
                            >
                                <div style={childCompStyle} >
                                    {templateLayoutData.length > 0 ? (
                                        templateLayoutData.map((element, index) => (
                                            <div
                                                key={element.id} // Use stable unique key
                                                // className={`${selectedElementData?.id === element.id ? "selectedElement" : "layoutElement"} position-relative dragClass`}
                                                style={element?.containerStyle || {}}
                                            >
                                                {getLayoutComponent(element)}
                                            </div>
                                        ))
                                    ) : (
                                        <div className="p-4">
                                            <h4 className="addLayoutSection text-center p-4">Add layout here</h4>
                                        </div>
                                    )}
                                </div>
                            </div> : <div
                                className=""
                                id="canvas-data"
                                style={parentCompStyle}
                            >
                                <img src={mobile_frame} alt="mobile_frame" style={{ height: "auto", width: "auto" }} />
                            </div>
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};

export default TemplatePreviewModal;
