import React, { Fragment, useEffect, useState } from 'react';
import { Home } from 'react-feather';
import { Link } from 'react-router-dom'
import Bookmark from './bookmark';
import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { UncontrolledTooltip } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import EyeIcon from "mdi-react/EyeIcon";
import EyeOffIcon from "mdi-react/EyeOffIcon";
import moment from "moment-timezone";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem } from 'reactstrap';
import { MdOpenInNew } from "react-icons/md";

const Breadcrumb = props => {

    const [breadcrumb, setBreadcrumb] = useState(props);
    const [loadingA, setLoadingA] = useState(false);
    const [referral_id, set_referral_id] = useState('');
    const [openPortalLogin, setOpenPortalLogin] = useState(false);
    const [adminPassword, setAdminPassword] = useState('');
    const [showNewPin, setShowNewPin] = useState(true);

    const showModal = () => {
        Swal.fire({
            title: 'Do you have the merchant consent to get their profile public?',
            input: 'select',
            inputOptions: {
                'talent': 'Talents',
                'customers': 'Customers',
                'organisers': 'Organizers',
                'venues': 'Venues',
                'guards': 'Guards',
                'others': 'Others'
            },
            inputPlaceholder: 'Select an option',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Not Sure',
            preConfirm: (value) => {
                if (!value) {
                    Swal.showValidationMessage('Please select an option!');
                }
                return value;
            }
        }).then((result) => {
            if (result.isConfirmed || result?.value) {
                try {
                    let postdata = { merchant_ref: breadcrumb.merchant_id_m, cat: result.value, toEnable: true };
                    client.post(api.make_default_template, postdata, async function (error, response) {
                        if (!error) {
                            if (typeof response.error !== "undefined") {
                                try {
                                    if (!response.error) {
                                        toast.success("Updated Successfully!");
                                        setBreadcrumb({ ...breadcrumb, merchant_info: { ...breadcrumb?.merchant_info, templateForOthers: true, templateForOthersCat: result.value } })
                                    }
                                } catch (e) { }
                            }
                        }
                    });
                } catch (err) {
                    console.log(err)
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                console.log('Not Sure');
            }
        });
    };

    const showModalNot = () => {
        Swal.fire({
            title: 'Remove as default template?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Not Sure',
        }).then((result) => {
            if (result?.isConfirmed || result?.value) {
                try {
                    let postdata = { merchant_ref: breadcrumb.merchant_id_m, cat: "", toEnable: false };
                    client.post(api.make_default_template, postdata, async function (error, response) {
                        if (!error) {
                            if (typeof response.error !== "undefined") {
                                try {
                                    if (!response.error) {
                                        toast.success("Updated Successfully!");
                                        setBreadcrumb({ ...breadcrumb, merchant_info: { ...breadcrumb?.merchant_info, templateForOthers: false, templateForOthersCat: '' } });
                                    }
                                } catch (e) { }
                            }
                        }
                    });
                } catch (err) {
                    console.log(err)
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                console.log('Not Sure');
            }
        });
    };

    const toggleNewPinFunc = () => { setShowNewPin(!showNewPin) };

    let getHostname = window && window.location.host.includes('dm.') ? 'dm.' : window && window.location.host.includes('localhost') ? 'dm.' : '';

    let sinceDate = new Date(props.create_date);

    // console.log(props)

    let dateDate = sinceDate.getDate()
    if (dateDate < 10) {
        dateDate = '0' + dateDate
    }
    let dateMonth = sinceDate.getMonth()
    if (dateMonth < 10) {
        dateMonth = '0' + dateMonth
    }

    let dateYear = sinceDate.getFullYear()
    sinceDate = `${dateDate}-${dateMonth}-${dateYear}`

    const changeISVerified = async (merchant_id, email_status) => {
        if (email_status == 1) return false;

        let willDelete = await swal({
            title: "Are you sure?",
            text: "Change status to Verified Merchant",
            icon: "warning",
            buttons: {
                cancel: true,
                confirm: {
                    text: "Yes, Please!",
                    closeModal: false,
                },
            },
            dangerMode: true,
        });

        if (willDelete) {
            let postdata = { merchant_id: merchant_id, field_type: "email_status", email_status: 1 };
            let message = "Internal error occured. Please contact support";
            let status = 0;
            setLoadingA(true)
            client.post(api.merchant_field_changes, postdata, async function (error, response) {
                swal.close();
                if (!error) {
                    // alert(JSON.stringify(response));
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                status = 1;
                                message = "Merchant Verified succefully";
                                toast.success("Updated Successfully!");
                                setBreadcrumb({ ...breadcrumb, user_email_status: 1 })
                            } else if (response.error && response.duplicate) {

                                setLoadingA(false)

                                await Swal.fire({
                                    title: 'Duplicate email found',
                                    text: "Please enter new email",
                                    input: 'email',
                                    inputPlaceholder: 'Enter your email address',
                                    showLoaderOnConfirm: true,
                                    preConfirm: (email) => {

                                        let postdata = { merchant_id: merchant_id, field_type: "email_status", email_status: 1, email, checkEmail: true };

                                        const config = {
                                            headers: {
                                                "x-access-token": "ipay-access-token",
                                                "authorization": localStorage.getItem("token"),
                                            }
                                        }

                                        return axios.post(`${client.url(api.merchant_field_changes)}`, postdata, config)
                                            .then(result => {

                                                let response = result.data;

                                                if (typeof response.error !== "undefined") {
                                                    try {
                                                        if (!response.error) {
                                                            status = 1;
                                                            message = "Merchant Verified succefully";
                                                            toast.success("Updated Successfully!");
                                                            setBreadcrumb({ ...breadcrumb, user_email_status: 1 })
                                                        } else if (response.error && response.duplicate) {

                                                            Swal.showValidationMessage(
                                                                "This email is already in use, Please try another."
                                                            )
                                                        } else {
                                                            status = 0;
                                                            message = response.message;
                                                        }
                                                    } catch (e) { }
                                                }
                                            });
                                    },
                                }).then((result) => {
                                    // console.log(result, "resultresult")
                                    // if (result.isConfirmed) {
                                    // 	Swal.fire({
                                    // 		icon: 'success',
                                    // 		title: 'Email is updated',
                                    // 		showConfirmButton: false,
                                    // 		timer: 1500
                                    // 	})
                                    // }
                                })

                            } else {
                                status = 0;
                                message = response.message;
                            }
                        } catch (e) { }
                    }
                }

                swal.close();
            });
        }
    };

    const changeISMobileVerified = async (merchant_id, mobile_status) => {
        if (mobile_status == 1) {
            return false
        } else {
            let willDelete = await swal({
                title: "Are you sure?",
                text: "Change mobile number to Verified ",
                icon: "warning",
                buttons: {
                    cancel: true,
                    confirm: {
                        text: "Yes, Please!",
                        closeModal: false,
                    },
                },
                dangerMode: true,
            });
            if (willDelete) {
                let postdata = { merchant_id: merchant_id, mobile_status: 1 };
                let message = "Internal error occured. Please contact support";
                setLoadingA(true);
                client.post(api.merchant_mobileNo_verify, postdata, async function (error, response) {
                    if (!error) {
                        if (typeof response.error !== "undefined") {
                            try {
                                if (!response.error) {

                                    toast.success("Mobile number verified successfully!");
                                    swal.close();
                                    setBreadcrumb({ ...breadcrumb, mobile_status: 1 })
                                } else {
                                    toast.error("Internal error occured. Please contact support");
                                }
                            } catch (e) { }
                        } else {
                            toast.error("Internal error occured. Please contact support");
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                    }
                });
            }
        }
    };

    // console.log(referral_id)

    useEffect(() => {
        setBreadcrumb(props)

        let postdata = { referral_code: props?.referral_code }
        if (props?.referral_code) {
            client.post(api.merchant_by_referral, postdata, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {

                                set_referral_id(response?.result?.merchantData?._id)

                                // console.log(response)

                            } else {
                                // console.log(response)

                                // toast.error("Internal error occured. Please contact support");
                            }
                        } catch (e) { }
                    } else {
                        // console.log(response)

                        // toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    console.log(response)

                    toast.error("Internal error occured. Please contact support");
                }
            });
        }




    }, [props])


    const render_date = (add_date) => {

        const convert_date = new Date(add_date);
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD-MM-YYYY");
        return added_date;
    };

    const toggleAutoLogin = () => {
        setOpenPortalLogin(!openPortalLogin);
    }

    const verifyAdminHandler = (e) => {
        setAdminPassword(e.target.value);
    }

    const handleAutoLogin = async (event) => {
        event.preventDefault();
        toggleAutoLogin();


        const postData = {
            admin_id: props.merchant_info.adminId,
            merchant_id: props.merchant_info._id,
            adminUser_id: localStorage.getItem("userId"),
            adminPassword: adminPassword
        }
        client.post(api.adminAutoLogin, postData, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            try {
                                const token = response.result.token;
                                let domain = "" // addcondiotn to check if dm then dm domain
                                let host = window.location.hostname
                                if (host == "localhost") {
                                    domain = "http://localhost:3001/"
                                } else if (host == "dm.admin.ipaypro.co") {
                                    domain = "https://dm.portal.linky.biz/"
                                } else {
                                    domain = "https://portal.linky.biz/"
                                }
                                const url = `${domain}admin-login?token=${token}&merchant_id=${response.merchant._id}&admin_id=${response.result.user._id}`;
                                // console.log(url, "MAIN URL")
                                window.open(url, "_blank")
                            } catch (err) {
                                console.log(err, "err")
                            }
                        } else {
                            toast.error(response.message);
                        }
                    } catch (err) {
                        toast.error(response.message);
                    }
                }
            } else {

            }

        })
    }

    return (
        <Fragment>

            <div className="container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col">
                            <div className={`page-header-left ${breadcrumb.customClass}`}>
                                <h6 className={`breadcrumbHthree mb-0 ${breadcrumb.customClass2}`}>

                                    {breadcrumb.title || 'NA'}
                                    {props.merchant_info &&
                                        <>
                                            {props.merchant_info.deactivate_topup && <span className='mx-2 mb-2' style={{ color: 'red', borderLeft: "1px solid", paddingLeft: "10px", textTransform: 'unset' }}>Top up Restricted</span>}
                                            {!props.merchant_info.deactivate_topup && <span className='mx-2 mb-2' style={{ color: 'green', borderLeft: "1px solid", paddingLeft: "10px", textTransform: 'unset' }}>Top up Active</span>}
                                    
                                            {props.merchant_info.status == 5 && <span className='mx-2 mb-2' style={{ color: 'red', borderLeft: "1px solid", paddingLeft: "10px", textTransform: 'unset' }}>Account Restricted</span>}
                                            {props.merchant_info.status == 1 && <span className='mx-2 mb-2' style={{ color: '#327EBC', borderLeft: "1px solid", paddingLeft: "10px", textTransform: 'unset' }}>Account Active</span>}
                                        </>
                                    }
                                    

                                    {props.merchant_info &&
                                        <>
                                            <br />
                                            {/* <b className='ml-2'>{breadcrumb.account_type ? `(${breadcrumb.account_type})` : null}</b> */}
                                            {props.merchant_info && <span className='' onClick={toggleAutoLogin} style={{ color: '#d23f99', textDecoration: "underline", cursor: 'pointer' }}>Login to Portal</span>}
                                            {/* <div className='d-flex align-items-center' style={{gap:'10px'}}> */}
                                        </>
                                    }
                                    <span className='d-flex'>{props.create_date !== undefined ? `Since ${render_date(props.create_date)}` : null}  <span className='d-inline-block ml-1'>{props.referral_code !== undefined && props.referral_code !== '' ? `Referred by: `
                                        : null}</span>
                                        {props.referral_code !== undefined && props.referral_code !== ''
                                            &&
                                            <span>
                                                <Link to={`/merchants/merchantDetails/${referral_id}`}>&nbsp;{props.referral_code}</Link>
                                            </span>
                                        }
                                        {props?.merchant_info?.unique_id &&
                                            <div className='d-flex ml-3'>
                                                <span style={{ color: "grey", textDecoration: "underline", textTransform: 'none' }}>
                                                    {`https://${getHostname}linky.biz/${props?.merchant_info?.unique_id}`}
                                                </span>
                                                <span onClick={() => {
                                                    window.open(`https://${getHostname}linky.biz/${props?.merchant_info?.unique_id}`, "_blank")
                                                }}>
                                                    <MdOpenInNew />
                                                </span>
                                            </div>}
                                        {props?.merchant_info?.unique_id && breadcrumb?.merchant_info?.templateForOthers === true && <div style={{ textDecoration: 'underline', marginLeft: '20px', cursor: 'pointer' }} onClick={showModalNot}>Remove template default</div>}
                                        {props?.merchant_info?.unique_id && !breadcrumb?.merchant_info?.templateForOthers && <div style={{ textDecoration: 'underline', marginLeft: '20px', cursor: 'pointer' }} onClick={showModal}>Make this template default</div>}
                                    </span>




                                </h6>
                                <Modal isOpen={openPortalLogin} toggle={toggleAutoLogin} className="modal-body" centered={true}>
                                    <ModalBody>
                                        <form onSubmit={(event) => handleAutoLogin(event)} className="needs-validation">
                                            <div className="my-4" style={{ fontSize: "3rem", textAlign: "center" }}>
                                                <h5>Enter your admin password to proceed.</h5>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="form__form-group-input-wrap position-relative">
                                                    <div className="form__form-group-field">
                                                        <input
                                                            className="rounded form-control align-self-center"
                                                            type={showNewPin ? "password" : "text"}
                                                            // size="40"
                                                            onChange={(e) => verifyAdminHandler(e)}
                                                            style={{ height: '38px' }}
                                                            placeholder="Please enter admin password"
                                                            required
                                                        ></input>
                                                        <div
                                                            className='d-flex align-items-center'
                                                            onClick={toggleNewPinFunc}
                                                            style={{ height: "36px", position: "absolute", top: "1px", right: "6px", border: "none", backgroundColor: "#fff" }}
                                                        >
                                                            {showNewPin === true ? <EyeOffIcon style={{ height: "18px", width: "18px", fill: "#999999", }} /> : <EyeIcon style={{ height: "18px", width: "18px", fill: "#999999" }} />}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-around">
                                                    <Button color="default" style={{ marginTop: "20px", marginRight: "20px" }} onClick={toggleAutoLogin}>
                                                        Cancel
                                                    </Button>
                                                    <Button color="primary" style={{ marginTop: "20px", marginRight: "20px" }} name="block">
                                                        Yes, Please!
                                                    </Button>
                                                </div>
                                            </div>
                                        </form>
                                    </ModalBody>
                                </Modal>
                                <ol className="breadcrumb pull-right" style={props.merchant_info ? {position : 'absolute', right : 0, top : 0} : {}}>
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard/default">
                                            <Home />
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">{breadcrumb.parent}</li>
                                    <li className="breadcrumb-item active">{breadcrumb.title}</li>
                                    {breadcrumb.mobile && <li className="breadcrumb-item active">{`${breadcrumb?.country?.phone_prefix}${breadcrumb?.mobile}`} {
                                        breadcrumb?.mobile_status == 1 ?
                                            <span><i className="fa fa-thumbs-up" id="mobile-verified" style={{ color: "green", marginLeft: "5px" }}></i>
                                                <UncontrolledTooltip placement="top" target="mobile-verified">
                                                    Mobile Verified
                                                </UncontrolledTooltip>
                                            </span> :
                                            <span onClick={() => changeISMobileVerified(breadcrumb.merchant_id_m, breadcrumb?.mobile_status)}>
                                                <i className="fa fa-thumbs-down" id="not-verified-mobile" style={{ color: "red", marginLeft: "5px" }}></i>
                                                <UncontrolledTooltip placement="top" target="not-verified-mobile">
                                                    Mobile not verified
                                                </UncontrolledTooltip>
                                            </span>
                                    }</li>}
                                    {breadcrumb?.user_email && <li className="breadcrumb-item active" style={{ textTransform: "none" }}>{breadcrumb.user_email}
                                        <span>{
                                            breadcrumb?.user_email_status == 1 ?
                                                <span><i className="fa fa-thumbs-up" id="email-verified" style={{ color: "green", marginLeft: "5px" }}></i>
                                                    <UncontrolledTooltip placement="top" target="email-verified">
                                                        Email Verified
                                                    </UncontrolledTooltip>
                                                </span> :
                                                <span onClick={() => changeISVerified(breadcrumb?.merchant_id_m, 0)}>
                                                    <i className="fa fa-thumbs-down" id="not-verified" style={{ color: "red", marginLeft: "5px" }}></i>
                                                    <UncontrolledTooltip placement="top" target="not-verified">
                                                        Email not verified
                                                    </UncontrolledTooltip>
                                                </span>
                                        }</span>
                                    </li>}
                                    {breadcrumb.ipay_id && <li className="breadcrumb-item active" style={{ textTransform: "none" }}>{breadcrumb.ipay_id}</li>}
                                    {breadcrumb.brandName && <li className="breadcrumb-item active" style={{ textTransform: "none" }}>{breadcrumb.brandName}</li>}
                                </ol>
                            </div>
                        </div>
                        {/* <!-- Bookmark Start--> */}
                        {/* <Bookmark /> */}
                        {/* <!-- Bookmark Ends--> */}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Breadcrumb
