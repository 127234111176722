import React, { Component, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Col, Row, FormFeedback, CustomInput } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import AddOfferModal from './AddOfferModal';
import AddOfferCatModal from './AddOfferCatModal';
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import moment from "moment";
//import Lightbox from 'react-image-lightbox';
import EditOfferModal from "./EditOfferModal";
import momentT from 'moment-timezone';
import CustomModal from "../../../components/ui-elements/CustomModal/CustomModal";
import TransactionCustomerData from "../../merchants/TransactionCustomerData";

class Offers extends Component {

    state = {
        merchant_id: this.props.merchant_id,
        offer_level: this.props.offer_level,
        merchant_info_full: this.props.merchant_info_full,
        wallet_info: this.props.wallet_info,
        merchantDataType: this.props.merchantDataType,
        addOfferModal: false,
        addOffercatModal: false,
        offers: [],
        categories: [],
        merchants: [],
        activePage: 1,
        pageSize: 50,
        isOpen: false,
        imageSrc: '',
        deleteModal: false,
        editModal: false,
        selectedOffer: null,
        selectedOfferMerchant: null,
        hideModal: false,
        imagePreview: false,
        statusModal: false,
        rejectClick: false,
        rejectStatusReason: "",
        rejectStatusModal: false,
        access_level: localStorage.getItem("accessLevel"),
        adminUser: JSON.parse(localStorage.getItem("user")),
        selectedOfferCat: null,
        transactions: null,
        searchData: [],
        allTransaction: 0,
    }


    getMerchantTransactions = (merchant_id, activePage, pageSize) => {

        let skip = (parseInt(activePage) - 1) * parseInt(pageSize);

        // console.log(skip, "skip")

        let postdata = {
            merchant_id: merchant_id,
            merchantDataType: this.state.merchantDataType?.type,
            limit: parseInt(pageSize),
            toSort: true,
            skip,
        };

        this.setState({ transaction_loading: true })

        client.post(api.fetch_transaction, postdata, (error, response) => {

            if (!error) {

                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            // console.log(response.result, "fetch transsaction data")
                            this.setState({
                                transactions: response.result.transactions,
                                searchData: response.result.transactions,
                                allTransaction: response.result.allTransaction
                            })
                            this.setState({ transaction_loading: false })
                        }

                    } catch (e) {
                        // console.log(e);
                        setTimeout(() => {
                            toast.error("Internal error occured. Please contact support");
                        }, 200);
                        this.setState({ transaction_loading: false })
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support");
                    }, 200);
                }
            } else {
                setTimeout(() => { toast.error("Internal error occured. Please contact support"); }, 200);
                this.setState({ transaction_loading: false })
            }



        });
    }

    toggleAddOfferModal = () => {
        this.setState({ addOfferModal: !this.state.addOfferModal });
    }

    componentWillMount() {
        this.fetc_offers();
        if (this.state.offer_level == 2) {
            this.setState({ merchants: [{ _id: this.state.merchant_id, business_name: this.props.merchant_info.business_name }] })
        } else {
            this.fetc_merchants();
        }
        this.getMerchantTransactions(this.state.merchant_id, 1, 15);
    }

    imageClicked = (src, offer) => {
        this.setState({ isOpen: true, imageSrc: src, imagePreview: true, selectedOffer: offer })
    }

    toggleImagePreview = () => {
        this.setState(prevState => {
            return {
                imagePreview: !prevState.imagePreview
            }
        })
    }

    renderImagePreviewModal = () => {
        const { selectedOffer } = this.state;
        // console.log(selectedOffer, "selectedOffer");
        return (
            <div>
                <CustomModal modalClose={this.toggleImagePreview}>
                    <div style={{
                        borderRadius: "5%",
                        backgroundImage: `url(${this.state.imageSrc})`,
                        height: "95%",
                        width: "93%",
                        position: "relative",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                    }}>
                        <div style={{
                            backgroundColor: "rgba(0, 0, 0, 0.2)",
                            borderRadius: "5%",
                            position: "absolute",
                            top: "0%",
                            left: "0%",
                            color: "white",
                            width: "100%",
                            height: "100%",
                            textAlign: "center",
                            paddingTop: "3rem"
                        }}>
                            {(selectedOffer.merchant && selectedOffer.merchant.profile_image) && <img
                                src={selectedOffer?.merchant?.profile_image.includes('dropbox') ? selectedOffer?.merchant?.profile_image : `${client.url(`images/merchant-profile/${selectedOffer?.merchant?.profile_image}`)}`}
                                alt="merchant-profile"
                                style={{ width: "60px", height: "60px", borderRadius: "50%", marginBottom: "10px" }} />}
                            {(selectedOffer.merchant && selectedOffer.merchant.business_name) && <h6 style={{ fontWeight: "bold" }}>Pay at {selectedOffer.merchant.business_name}</h6>}
                            <h5 style={{ fontWeight: "bold", display: "inline-block", width: "90%" }}>{selectedOffer.offer_title}</h5>
                        </div>
                    </div>
                </CustomModal>
            </div>
        )
    }

    fetc_offers = () => {
        let { offers, merchant_id, offer_level } = this.state;
        let current = this;
        let apply_filter = false;
        if (offer_level == 2) {
            apply_filter = true;
        }
        // console.log(offer_level);
        client.post(api.fetch_offers, { merchant_id: merchant_id, offer_level: offer_level, apply_filter: apply_filter }, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            // console.log(response.result.data, "GET DATA");
                            current.setState({ offers: response.result.data.reverse(), categories: response.result.category ? response.result.category : [] });
                        } else {
                            // toast.error(response.message);
                        }
                    } catch (e) {
                        // toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    // toast.error("Internal error occured. Please contact support");
                }
            } else {
                // toast.error("Internal error occured. Please contact support");
            }
        });
    }

    fetc_merchants = () => {
        let current = this;
        client.post(api.getActiveMerchants, { verifiedOnly: true }, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            // console.log(response.result.data);
                            current.setState({ merchants: response.result.data });
                        } else {
                            // toast.error(response.message);
                        }
                    } catch (e) {
                        // toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    // toast.error("Internal error occured. Please contact support");
                }
            } else {
                // toast.error("Internal error occured. Please contact support");
            }
        });
    }

    getmerchant(item) {

        try {
            if (item.offer_level == 2) {
                return item.merchant.business_name;
            } else {
                return "All Merchants";
            }
        } catch {
            return "";
        }
    }

    deleteClick = (offer) => {

        this.setState(prevState => {
            return {
                ...prevState,
                deleteModal: !prevState.deleteModal,
                selectedOffer: offer
            }
        });
    }

    toggleDeleteModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                deleteModal: !prevState.deleteModal
            }
        });
    }

    deleteOffer = (e) => {
        e.preventDefault();

        const data = { offerId: this.state.selectedOffer._id, admin_id: this.state.adminUser._id }
        // console.log(data);
        client.post(api.delete_offers, data, (error, response) => {
            if (!error) {

                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success(response.message);
                            this.setState({ deleteModal: false });
                            this.fetc_offers()
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }

        });
    }

    renderDeleteModal = () => {
        const { deleteModal } = this.state;

        return (
            <Modal isOpen={deleteModal} toggle={this.toggleDeleteModal} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" onSubmit={this.deleteOffer}>
                    <ModalHeader><h5 style={{ fontSize: "1.15rem" }}>Delete Offer</h5></ModalHeader>
                    <ModalBody>
                        <ModalBody style={{ padding: "16px 0" }}>
                            <h5 style={{ fontSize: "1.15rem" }}>Are you sure you want to delete this offer ?</h5>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="default" onClick={this.toggleDeleteModal}>Not sure!</Button>
                            <Button color="primary" type="submit"  >Yes Please!</Button>
                        </ModalFooter>
                    </ModalBody>
                </form>
            </Modal>
        );
    }

    onEditClick = (offer) => {
        const foundMerchant = this.state.merchants.find(el => {
            return el._id == offer.fk_merchant_id
        });
        this.setState(prevState => {
            return {
                editModal: !prevState.editModal,
                selectedOffer: offer,
                selectedOfferMerchant: foundMerchant
            }
        });
    }


    toggleEditModal = () => {
        this.setState(prevState => {
            return {
                editModal: !prevState.editModal,

            }
        });
    }

    onhideClick = (offer) => {
        this.setState(prevState => {
            return {
                hideModal: !prevState.hideModal,
                selectedOffer: offer,
            }
        })
    }

    toggleHideModal = () => {
        this.setState(prevState => {
            return {
                hideModal: !prevState.hideModal,

            }
        });
    }

    hideOfferStatusSubmit = (event) => {
        event.preventDefault();
        const { selectedOffer } = this.state;

        const data = {
            offer_id: this.state.selectedOffer._id,
            admin_id: this.state.adminUser._id,
            merchant_id: this.state.selectedOffer.fk_merchant_id, status: selectedOffer.status == 1 ? 3 : 1
        }
        // console.log(data);
        client.post(api.update_offers_status, data, (error, response) => {
            if (!error) {

                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success(response.message);
                            this.setState({ hideModal: false });
                            this.fetc_offers()
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }

        });
    }

    renderHideModal = () => {
        const { selectedOffer } = this.state;
        return (
            <Modal isOpen={this.state.hideModal} toggle={this.toggleHideModal} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" onSubmit={this.hideOfferStatusSubmit}>
                    <ModalHeader toggle={this.toggleHideModal} ><h5 style={{ fontSize: "1.15rem" }}>Hide offer</h5></ModalHeader>
                    <ModalBody>
                        <ModalBody style={{ padding: "16px 0" }}>
                            <h5 style={{ fontSize: "1.15rem" }}>Are you sure you want to {selectedOffer.status == 1 ? "hide" : "unhide"} this offer ?</h5>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="default" onClick={this.toggleHideModal}>Not sure!</Button>
                            <Button color="primary" type="submit"  >Yes Please!</Button>
                        </ModalFooter>
                    </ModalBody>
                </form>
            </Modal>
        );
    }

    onStatusClicked = (item) => {
        this.setState({ statusModal: true, selectedOffer: item, rejectClick: false })

    }

    toggleStatusModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                statusModal: !prevState.statusModal
            }
        })
    }

    toggleAddOfferModalCat = () => {
        this.setState({ addOffercatModal: !this.state.addOffercatModal, editcat: false, selectedOfferCat: null });
    }

    onEditClickcat = (item, editCatModal) => {
        this.setState({ selectedOfferCat: item, addOffercatModal: true, editcat: true });
    }

    verifyStatusSubmit = () => {
        const postData = {
            offer_id: this.state.selectedOffer._id, admin_id: this.state.adminUser._id,
            verifyStatus: this.state.rejectClick ? 2 : 1, rejectReason: this.state.rejectStatusReason
        };
        // console.log(this.state.rejectStatusReason);
        client.post(api.update_offerverifyStatus, postData, (error, response) => {
            if (!error) {
                // console.log(response);
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            // console.log(response);
                            this.setState({ statusModal: false, rejectClick: false });
                            this.fetc_offers();
                            toast.success("Offer verify status changed")
                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        console.log(e);
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
        });

    }

    renderStatusModal = () => {
        const style = {
            outline: "none",
            ':focus': {
                outlineColor: "black"
            }
        };

        return (
            <Modal isOpen={this.state.statusModal} toggle={this.toggleStatusModal} className="modal-body" centered={true}>
                <form className="theme-form" >
                    <ModalHeader toggle={this.toggleStatusModal} >
                        {this.state.rejectClick ? "Reject Offer" : "Update Offer status"}
                    </ModalHeader>
                    <ModalBody>
                        <ModalBody style={{ padding: "16px 0" }}>
                            {!this.state.rejectClick &&
                                <Fragment>
                                    <h6 style={{ fontSize: "1.15rem", marginBottom: "0.5rem" }}>Are you sure you want to activate the promo?</h6>
                                    <p style={{ color: "red" }}>Note: Kindly Preview the promo before activating it.</p>
                                </Fragment>}
                            {this.state.rejectClick && <h6 style={{ fontSize: "1.15rem", marginBottom: "0.5rem" }}>Are you sure you want to reject the offer?</h6>}
                            {this.state.rejectClick &&
                                <Fragment>
                                    <input type="text" size="40" value={this.state.rejectStatusReason} onChange={(event) => this.setState({ rejectStatusReason: event.target.value })}
                                        style={style} placeholder="Enter a reason for your action" maxLength="30"
                                        required></input>
                                    {/* <p style={{ color: "red" }}>Note: This reason will be visible to merchants to make changes accordingly in the offer, so enter valid reason.</p> */}
                                </Fragment>}
                        </ModalBody>
                        <ModalFooter>
                            {!this.state.rejectClick && <Button color="danger" type="button" onClick={() => this.setState({ rejectClick: true })}>Reject</Button>}
                            {<Button color="primary" type="button" onClick={this.verifyStatusSubmit}>Yes Please!</Button>}
                            {<Button color="default" onClick={this.toggleStatusModal}>Not sure!</Button>}


                        </ModalFooter>
                    </ModalBody>
                </form>
            </Modal>);
    }

    onRejectStatusClick = (item) => {
        this.setState({ rejectStatusModal: true, selectedOffer: item })
    }

    toggleRejectStatusModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                rejectStatusModal: !prevState.rejectStatusModal
            }
        })
    }

    renderRejectStatusModal = () => {
        return (
            <Modal isOpen={this.state.rejectStatusModal} toggle={this.toggleRejectStatusModal} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" >
                    <ModalBody style={{ textAlign: "center" }}>
                        <div style={{ fontSize: "2rem", textAlign: "center" }}><i className="icofont icofont-warning" style={{ color: "#FF2046" }}></i></div>
                        <h6>{this.state.selectedOffer.unverified_reason}</h6>
                        <Button className="btn btn-danger" style={{ width: "100%", textAlign: "center", fontSize: "1.1rem", marginTop: "10px" }} onClick={this.toggleRejectStatusModal}>Dismiss</Button>
                    </ModalBody>

                </form>
            </Modal>
        );
    }


    render() {

        let { offers, merchant_id, offer_level, merchants, access_level, selectedOffer, imagePreview, editModal, selectedOfferMerchant, categories } = this.state;
        // console.log(offers, "offers");
        return (
            <>
                {selectedOffer && this.renderDeleteModal()}
                {selectedOffer && this.renderHideModal()}
                {imagePreview && this.renderImagePreviewModal()}
                {selectedOffer && this.renderStatusModal()}
                {selectedOffer && this.renderRejectStatusModal()}
                {editModal && <EditOfferModal selectedOffer={selectedOffer} editModal={editModal} fetc_offers={this.fetc_offers}
                    toggleEditModal={this.toggleEditModal} merchants={merchants} selectedOfferMerchant={selectedOfferMerchant} categories={this.state.categories} />}

                <Tabs className="col-sm-12" defaultIndex={this.state.tabIndex} onSelect={index => this.setState({ tabIndex: index })} style={{ padding: "0" }}>
                    <TabList className="tabs tab-title" >
                        <Tab style={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }} >
                            All Offers
                        </Tab>
                        <Tab style={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }}>
                            Offers Category
                        </Tab>
                        <Tab style={{ marginTop: "0.5rem", display: "flex", alignItems: "center" }}>
                            Customers
                        </Tab>
                    </TabList>
                    <div className="tab-content-cls">
                        <TabPanel>
                            <div className="card">
                                <div className="card-body" style={{ padding: "1rem", paddingTop: 0 }}>
                                    <div className="btn-group pull-right" role="group" aria-label="Basic example" style={{ marginBottom: "10px" }}>
                                        {<button onClick={() => this.toggleAddOfferModal()} className="btn btn-light btn-xs" ><i className="fa fa-plus-square-o" style={{ margin: "0px 7px 0 0" }}></i> Add New Offer </button>}
                                    </div>

                                    <div className="table-responsive" >
                                        <table className="table table-border-horizontal" >
                                            <thead>
                                                <tr style={{ background: '#f9fafe' }}>
                                                    <th scope="col" >#</th>
                                                    <th scope="col" style={{ width: "11%" }}>Merchant Type</th>
                                                    <th scope="col">Category</th>
                                                    <th scope="col" style={{ width: "15%" }}>Offer</th>
                                                    {/* <th scope="col">Activate Offer</th> */}
                                                    <th scope="col" style={{ width: "14%" }}>Offer Type</th>
                                                    <th scope="col">Valid</th>
                                                    <th scope="col">Eligibility</th>
                                                    <th scope="col">Image</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {offers && offers.length > 0 ? offers.map((item, index) => {

                                                    let endDate = `${moment(item.valid_to).format("YYYY-MM-DD")} ${momentT(item.end_time).format("hh:mm A")}`;
                                                    let currDate = moment().format("YYYY-MM-DD hh:mm A");

                                                    return (
                                                        <tr key={item._id}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td style={{ width: "11%" }}>{this.getmerchant(item)}</td>
                                                            <td>{item.offer_category == 1 ? "Online Shopping" : "In-Store Purchase"}<br />
                                                                {item.filter_category ? `(${item.filter_category})` : ''}
                                                            </td>
                                                            <td style={{ width: "15%" }}>{item.offer_title}</td>
                                                            {/* <td>{item.activate_type == 1 ? "Automatic" : "coupon : " + item.coupon_code}</td> */}
                                                            <td style={{ width: "14%" }}>{item.offer_type == 1 ? item.offer_item : "Cashback : " + (item.cashback_type == 1 ? "$" : "") + item.cashback + (item.cashback_type == 2 ? "%" : "")}</td>
                                                            <td>{moment(item.valid_from).format("DD/MM/YYYY")} <br />To<br /> {moment(item.valid_to).format("DD/MM/YYYY")}</td>
                                                            <td>
                                                                Min. Trans : {item.min_transaction}<br />
                                                                Min. Amount : ${item.min_order_amount}
                                                            </td>
                                                            <th scope="row">
                                                                <img
                                                                    src={item.offer_image.includes('dropbox') ? item.offer_image : `${client.url(`images/offer-images/${item.offer_image}`)}`}
                                                                    onClick={() => this.imageClicked(item.offer_image.includes('dropbox') ? item.offer_image : `${client.url(`images/offer-images/${item.offer_image}`)}`, item)}
                                                                    style={{ width: "25px", height: "25px", cursor: "pointer" }}
                                                                />
                                                            </th>
                                                            <td>
                                                                {item.verified == 0 && item.status == 1 && moment(endDate, "YYYY-MM-DD hh:mm A").isAfter(currDate, "YYYY-MM-DD hh:mm A") ?
                                                                    <span onClick={() => this.onStatusClicked(item)} style={{ cursor: "pointer", backgroundColor: "#ffc107", padding: "0.4rem 0.8rem", borderRadius: "20px", color: "black" }}>Under Review</span>
                                                                    :
                                                                    <span >{item.verified == 2 ? <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => this.onRejectStatusClick(item)}>Rejected</span>
                                                                        : ((item.verified == 1 && item.status == 4) || moment(endDate, "YYYY-MM-DD hh:mm A").isBefore(currDate, "YYYY-MM-DD hh:mm A")) ? <span style={{ backgroundColor: "red", padding: "0.4rem 0.8rem", borderRadius: "20px", color: "white" }}>Expired</span>
                                                                            : item.verified == 1 && item.status == 1 ? <span style={{ backgroundColor: "green", padding: "0.4rem 0.8rem", borderRadius: "20px", color: "white" }}>Active</span>
                                                                                : <span onClick={() => this.onStatusClicked(item)} style={{ cursor: "pointer", backgroundColor: "#ffc107", padding: "0.4rem 0.8rem", borderRadius: "20px", color: "black" }}>Under Review</span>}</span>
                                                                }
                                                            </td>
                                                            {access_level != 1 && <td>
                                                                {access_level == 3 && <span style={{ marginRight: "10px", fontSize: "0.75rem", cursor: "pointer" }} onClick={() => this.deleteClick(item)}>
                                                                    <i style={{ color: "red" }} id="delete" className="fa fa-trash-o"></i>
                                                                </span>}
                                                                <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "black", cursor: "pointer" }} onClick={() => this.onEditClick(item)}>
                                                                    <i className="fa fa-edit"></i>
                                                                </span>
                                                                <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "black", cursor: "pointer" }} onClick={() => this.onhideClick(item)}>
                                                                    {item.status == 1 ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                                                                </span>
                                                            </td>}

                                                        </tr>
                                                    );
                                                })
                                                    : null}
                                            </tbody>
                                        </table>
                                        {offers && offers.length > 0 ? null :
                                            <h6 style={{ textAlign: "center" }}> No records found! </h6>}
                                        {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                            <select className="custom-select" style={{ width: "35%", fontSize: "0.63rem", marginRight: "10px" }} onChange={this.setPageSize} value={this.state.pageSize}>
                                                                <option value="15">15</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="75">75</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            <span style={{ fontSize: "0.63rem" }}>Records per Page</span>
                                                        </div>
                                                        <PaginationComponent
                                                            totalItems={showVisitors.length}
                                                            pageSize={this.state.pageSize}
                                                            onSelect={this.handlePageChange} />
                                                    </div> */}
                                    </div>

                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="card">
                                <div className="card-body" style={{ padding: "1rem", paddingTop: 0 }}>
                                    <div className="btn-group pull-right" role="group" aria-label="Basic example" style={{ marginBottom: "10px" }}>
                                        {<button onClick={() => this.toggleAddOfferModalCat()} className="btn btn-light btn-xs" ><i className="fa fa-plus-square-o" style={{ margin: "0px 7px 0 0" }}></i> Add Category </button>}
                                    </div>

                                    <div className="table-responsive" >
                                        <table className="table table-border-horizontal" >
                                            <thead>
                                                <tr style={{ background: '#f9fafe' }}>
                                                    <th scope="col" style={{ width: "15%" }}>#</th>
                                                    <th scope="col">Title</th>
                                                    <th scope="col" style={{ width: "15%" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {categories && categories.length > 0 ? categories.map((item, index) => {
                                                    return (
                                                        <tr key={item._id}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{item.filter_title}</td>
                                                            <td>
                                                                <span style={{ marginRight: "10px", fontSize: "0.75rem", color: "black", cursor: "pointer" }} onClick={() => this.onEditClickcat(item, true)}>
                                                                    <i className="fa fa-edit"></i>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                    : null}
                                            </tbody>
                                        </table>
                                        {categories && categories.length > 0 ? null :
                                            <h6 style={{ textAlign: "center" }}> No records found! </h6>}
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <TransactionCustomerData
                                merchant_id={merchant_id}
                                transactions={this.state.transactions}
                            />
                        </TabPanel>
                    </div>
                </Tabs>
                <AddOfferCatModal isOpen={this.state.addOffercatModal} fetc_offers={this.fetc_offers} toggleModal={() => this.toggleAddOfferModalCat()} selectedOfferCat={this.state.selectedOfferCat} editcat={this.state.editcat} />

                <AddOfferModal isOpen={this.state.addOfferModal} fetc_offers={this.fetc_offers} toggleModal={() => this.toggleAddOfferModal()} merchant_id={merchant_id} offer_level={offer_level} merchants={merchants} categories={this.state.categories} />
            </>
        );
    }
}

export default Offers;