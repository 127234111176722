import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { DisappearedLoading } from "react-loadingg";
import { useLocation } from 'react-router-dom';
import { setLayoutData, setSelectedData, setOuterData } from "../../../store/templateEditorData";
import { useSelector, useDispatch } from "react-redux";
import './component/EmailTemplate.scss';
import { SideBarComponent } from "./component/SideBarComponent";
import CanvasComponent from "./component/CanvasComponent";
import PreviewModal from "./component/TemplatePreview/TemplatePreview";


const Add_Mail_template = (props) => {

  const history = useHistory();
  const location = useLocation();
  const { state } = location.state || {};
  const dispatch = useDispatch();

  const { templateLayoutData, selectedElementData, outderData } = useSelector((state) => state.emailTemplateEditorData);

  const [editorState, setEditorState] = useState('');
  const [templateId, setTemplateId] = useState();
  const [loading, setLoading] = useState(false);
  const [templateData, setTemplateData] = useState(state);
  const [tempContent, setTempContent] = useState();

  // console.log(state, "state");

  // console.log(templateData, "templateData");
  // const getTemplateDetails = async () => {
  //   let postdata = { template_id: templateId };
  //   setLoading(true);
  //   client.post(api.get_mail_templates_details, postdata, async function (error, response) {
  //     console.log(response, " template response")
  //     if (!error) {
  //       if (typeof response.error !== "undefined") {
  //         try {
  //           if (!response.error) {
  //             setTemplateData(response.result.contactDetails);
  //           } else {
  //             toast.error(response.message);
  //           }
  //         } catch (e) {
  //           toast.error("Internal error occured. Please contact support");
  //         }
  //       } else {
  //         toast.error("Internal error occured. Please contact support");
  //       }
  //     } else {
  //       toast.error("Internal error occured. Please contact support");
  //     }
  //     setLoading(false);
  //   });
  // };

  // useEffect(() => {
  //   if (templateId) {
  //     getTemplateDetails();
  //   }

  // }, [templateId])


  // useEffect(() => {
  //   document.querySelector(".page-main-header").classList.add("open");
  //   document.querySelector(".sidebar1").classList.add("open");
  //   document.querySelector(".page-body-above").style.marginLeft = "0px";
  //   document.querySelector(".page-body").style.marginLeft = "0px";
  //   document.querySelector(".page-body").style.transition = "0.8s";
  // }, [])

  const [previewModal, setPreviewModal] = useState(false);
  const togglePreModal = () => { setPreviewModal(!previewModal) }



  return (
    <Fragment>
      <div className="w-100 h-100 row mx-0">
        <div className="col-lg-12 col-sm-12 my-3 d-flex align-items-center justify-content-between" style={{ fontSize: "12px" }}>
          <div className="w-50">
            <Breadcrumb title={"Mail template details"} parent={"Home"} />
          </div>
          <div className="w-50 d-flex align-items-center justify-content-end">
            <div
              className="border mr-2 bg-white"
              style={{ padding: "0.5rem 1.5rem", fontSize: "13px", borderRadius: "0.3rem", cursor: "pointer" }}
              onClick={() => { history.push('/settings/speed_mails') }}
            > Cancel</div>
            <div
              className="border mr-2 bg-white"
              style={{ padding: "0.5rem 1.5rem", fontSize: "13px", borderRadius: "0.3rem", cursor: "pointer" }}
            > Save</div>
            <div
              className="border bg-white"
              style={{ padding: "0.5rem 1.5rem", fontSize: "13px", borderRadius: "0.3rem", cursor: "pointer" }}
              onClick={() => { togglePreModal() }}
            > Preview</div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body" style={{ padding: "20px 15px" }}>
                  <form className="theme-form" onSubmit={() => { }}  >
                    {/* <label className="col-sm-12 col-form-label mb-2" htmlFor="Header_text"><h4>Email Setting of {templateData?.templ_name} </h4></label> */}
                    <div className="form-group row mb-0">
                      <label className="col-sm-1 col-form-label" htmlFor="Templatename">Name</label>
                      <div className="col-sm-5">
                        <input className="form-control" id="templ_name" type="text" value={templateData?.templ_name} name="templ_name" onChange={(event) => { setTemplateData((prevData) => ({ ...prevData, templ_name: event.target.value })) }} />
                      </div>

                      <label className="col-sm-1 col-form-label" htmlFor="inputEmail3">Subject</label>
                      <div className="col-sm-5">
                        <input className="form-control" id="inputEmail3" type="text" value={templateData?.templ_subject} name="templ_subject" onChange={(event) => { setTemplateData((prevData) => ({ ...prevData, templ_subject: event.target.value })) }} />
                      </div>
                    </div>

                    {/* <div className="form-group row">
                      <label className="col-sm-1 col-form-label" htmlFor="inputPassword3">To</label>
                      <div className="col-sm-5">
                        <input className="form-control" id="inputnumber" name="templ_to" type="text" onChange={() => { }} />
                      </div>

                      <label className="col-sm-1 col-form-label" htmlFor="inputPassword3">Test Mail</label>
                      <div className="col-sm-5">
                        <input className="form-control" id="test_mail" name="templ_test_mail" type="text" value={''} onChange={() => { }} />
                      </div>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="py-3" style={{ backgroundColor: "#EFEFF1" }}>
            <div className="row template_editor_comp">
              <SideBarComponent />
              <CanvasComponent previewModal={previewModal} />
            </div>
          </div>
        </div>
      </div >
      {previewModal && <PreviewModal
        isOpen={previewModal}
        toggle={togglePreModal}
      />}
    </Fragment >
  )
}

export default Add_Mail_template;
