import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from "redux-form";
import TodoApp from './todos.reducer';
import EmailApp from './email.reducer';
import ChatApp from './chat.reducer';
import EcommerceApp from './ecommerce.reducer';
import WishlistApp from './wishlist.reducer';
import Filters from './filters.reducer';
import Customizer from './customizer.reducer';

import authReducer from "../store/reducers/auth";
import headerReducer from '../store/reducers/header';
import appReducer from "../store/reducers/app";
import categoryReducer from "../store/reducers/category";
import sliderReducer from "../store/reducers/slider"
import merchantReducer from '../store/reducers/merchant';
import reservationStructure from '../store/reducers/reservationStructure';
import phoneViewPageReducer from '../store/reducers/phoneViewPageReducer';
import emailTemplateEditorData from "../store/templateEditorData";



const reducers = combineReducers({
    form: reduxFormReducer,
    TodoApp,
    EmailApp,
    ChatApp,
    data: EcommerceApp,
    WishlistApp,
    filters: Filters,
    Customizer,

    auth: authReducer,
    header: headerReducer,
    app: appReducer,
    merchant: merchantReducer,
    reservationStructure: reservationStructure,
    category: categoryReducer,
    slider: sliderReducer,
    phoneViewPageReducer: phoneViewPageReducer,
    emailTemplateEditorData
});

export default reducers;