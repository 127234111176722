import React, { useState, useCallback, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LuMove } from "react-icons/lu";
import { PiCopySimpleThin } from "react-icons/pi";
import { MdOutlineDelete } from "react-icons/md";
import DragListView from "react-drag-listview";
import { v4 as uuidv4 } from "uuid"; // Import UUID for unique IDs
import { setLayoutData, setSelectedElement, updateTemplateLayout, setHtmlData } from "../../../../store/templateEditorData";
import ColoumnElement from "./LayoutElement/ColumnElement"
import { ToolBarComponent } from "./ToolbarComponent";
import TextElement from "./LayoutElement/TextElement"
import ImageElement from "./LayoutElement/ImageElement"
import { ImageAndTextElement } from "./LayoutElement/ImageAndTextElement"
import ButtonElement from "./LayoutElement/ButtonElements"
import TableElement from "./LayoutElement/TableElement"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Popover, PopoverBody } from "reactstrap";
import './EmailTemplate.scss';
import { TbTableOptions } from "react-icons/tb";
import { RxGear } from "react-icons/rx";
import { MdDeleteOutline } from "react-icons/md";
import { produce } from "immer";


const CanvasComponent = ({ previewModal }) => {

  const dispatch = useDispatch();
  const {
    templateLayoutData,
    selectedElementData,
    outderData,
    tableProps,
    parentCompStyle,
    childCompStyle
  } = useSelector((state) => state.emailTemplateEditorData);
  const [dragOver, setDragOver] = useState(false);


  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState(null);
  const toggleModal = () => { setModalOpen(!modalOpen) };

  const [buttonProps, setButtonProps] = useState({ btnName: "", linkType: "", btnLink: "" });

  const handleAddButton = () => {
    if (selectedBtn) {
      let creatElem = { ...selectedBtn, content: buttonProps.btnName, href: buttonProps.btnLink };
      dispatch(setLayoutData([...templateLayoutData, creatElem]));
      dispatch(setSelectedElement(creatElem));
      setButtonProps({ btnName: "", linkType: "", btnLink: "" });
      toggleModal();
    }
  }


  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      setDragOver(false);
      const droppedComponent = event.dataTransfer.getData("component");
      if (droppedComponent) {
        let elemData = JSON.parse(droppedComponent);
        // console.log(elemData, "elemData");
        if (elemData.type === "normalbutton" || elemData.type === "roundedbutton" || elemData.type === "ovalbutton") {
          setSelectedBtn(elemData);
          toggleModal()
        } else {
          dispatch(setLayoutData([...templateLayoutData, elemData]));
        }
      }
    },
    [dispatch, templateLayoutData]
  );

  // Handle drag over event
  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  // Get the appropriate component layout
  const getLayoutComponent = (elemContent) => {
    switch (elemContent.type) {
      case "heading1":
      case "heading2":
      case "paragraph":
        return <TextElement elemContent={elemContent} />;
      case "1image":
      case "1+1image":
      case "2+1image":
        return <ImageElement elemContent={elemContent} isEdit={true} />;
      case "10pxspace":
      case "15pxspace":
      case "20pxspace":
        return <div style={elemContent.style}><span>&nbsp;</span></div>;
      case "image+text":
      case "text+image":
      case "text+image+text":
      case "image+text+image":
        return <ImageAndTextElement elemContent={elemContent} isEdit={true} />;
      case "normalbutton":
      case "roundedbutton":
      case "ovalbutton":
        return <ButtonElement elemContent={elemContent} />;
      case "basictable":
        return <TableElement elemContent={elemContent} />
      case "2columns":
      case "3columns":
        return <ColoumnElement elemContent={elemContent} />;
      default:
        return null;
    }
  };

  // Delete an element from the template
  const deleteElement = useCallback(
    (elementData) => {
      const filteredData = templateLayoutData.filter(el => el.id !== elementData.id);
      // console.log(filteredData, "filteredData");
      dispatch(setSelectedElement(null));
      dispatch(setLayoutData(filteredData));
    },
    [dispatch, templateLayoutData]
  );

  // Copy an element
  const copyElement = useCallback(
    (id) => {
      const elementToCopy = templateLayoutData.find(el => el.id === id);
      if (elementToCopy) {
        const copiedElement = { ...elementToCopy, id: uuidv4() }; // Generate unique ID
        dispatch(setSelectedElement(copiedElement));
        dispatch(setLayoutData([...templateLayoutData, copiedElement]));
      }
    },
    [dispatch, templateLayoutData]
  );

  // Handle drag-and-drop sorting
  const onDragEnd = (fromIndex, toIndex) => {
    if (toIndex < 0) return;
    const updatedItems = [...templateLayoutData];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);
    dispatch(setLayoutData(updatedItems));
  };

  const dragProps = {
    onDragEnd,
    nodeSelector: ".dragClass",
    handleSelector: ".drag_handle",
  };

  console.log(selectedElementData, "selected");
  console.log(templateLayoutData, "LayoutData---")


  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [contextMenuPosition, setContextMenuPosition] = useState({ top: 0, left: 0 });
  const popoverRef = useRef(null);

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const handleContextMenu = (event, rowIndex, colIndex) => {
    event.preventDefault();
    setPopoverOpen(true);
  };

  const handleAction = (action) => {

    let preTableData = { ...selectedElementData };
    const { headers, data } = preTableData.tableData;

    if (tableProps && tableProps.type === "data" && action == "insertRowBelow") {

      const updatedData = data.map(row => [...row]);
      const newRow = new Array(headers.length || 0).fill("");
      updatedData.splice(tableProps.rowIndex + 1, 0, newRow);
      preTableData = { ...preTableData, tableData: { ...preTableData.tableData, data: updatedData } };
      dispatch(updateTemplateLayout({ data: preTableData }));

    } else if (tableProps && tableProps.type === "data" && action == "insertRowAbove") {

      const updatedData = data.map(row => [...row]);
      const newRow = new Array(headers.length || 0).fill("");
      updatedData.splice(tableProps.rowIndex, 0, newRow);
      preTableData = { ...preTableData, tableData: { ...preTableData.tableData, data: updatedData } };
      dispatch(updateTemplateLayout({ data: preTableData }));

    } else if (tableProps && action == "insertColumnAfter") {

      const newHeaders = produce(headers, (draft) => {
        draft.splice(tableProps.colIndex + 1, 0, '');
      });

      const newData = produce(data, (draft) => {
        draft.forEach(row => row.splice(tableProps.colIndex + 1, 0, ""));
      });

      preTableData = { ...preTableData, tableData: { ...preTableData.tableData, headers: newHeaders, data: newData } };
      dispatch(updateTemplateLayout({ data: preTableData }));

    } else if (tableProps && action == "insertColumnBefore") {

      const newHeaders = produce(headers, (draft) => {
        draft.splice(tableProps.colIndex, 0, '');
      });

      const newData = produce(data, (draft) => {
        draft.forEach(row => row.splice(tableProps.colIndex, 0, ""));
      });

      preTableData = { ...preTableData, tableData: { ...preTableData.tableData, headers: newHeaders, data: newData } };
      dispatch(updateTemplateLayout({ data: preTableData }));
    } else if (tableProps && action == "deleteColumn") {

      const updatedHeaders = headers.filter((_, index) => index !== tableProps.colIndex);
      const updatedData = data.map((row) => row.filter((_, index) => index !== tableProps.colIndex));
      preTableData = {
        ...preTableData,
        tableData: {
          headers: updatedHeaders,
          data: updatedData
        }
      };

      dispatch(updateTemplateLayout({ data: preTableData }));
    } else if (tableProps && action == "deleteRow") {
      const updatedData = produce(data, (draft) => {
        draft.splice(tableProps.rowIndex, 1); // Remove row at index
      });
      preTableData = { ...preTableData, tableData: { ...preTableData.tableData, data: updatedData } };
      dispatch(updateTemplateLayout({ data: preTableData }));
    }
    setPopoverOpen(false);
  };


  useEffect(() => {
    if (previewModal) {
      const canvas = document.getElementById("canvas-data"); // Replace with actual ID
      if (canvas) {
        dispatch(setHtmlData(canvas.innerHTML));
      }
    }
  }, [previewModal])


  return (
    <>
      <div
        className="canvasLayout col-9 position-relative border"
        id="canvas-data"
        style={parentCompStyle}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        {selectedElementData && <ToolBarComponent />}
        <div className="child_comp" style={childCompStyle}        >
          <DragListView {...dragProps}>
            {templateLayoutData.length > 0 ? (
              templateLayoutData.map((element, index) => (
                <div
                  key={element.id} // Use stable unique key
                  className={`${selectedElementData?.id === element.id ? "selectedElement" : "layoutElement"} position-relative dragClass`}
                  onClick={() => dispatch(setSelectedElement(element))}
                  style={element?.containerStyle || {}}
                >
                  {getLayoutComponent(element)}

                  {selectedElementData?.id === element.id && (
                    <>
                      <span className="elementController drag_handle" style={{ position: "absolute", top: "-1px", left: "-37px", width: "35px" }}>
                        <LuMove style={{ fontSize: "16px", color: "#fff" }} />
                      </span>
                      <div className="d-flex flex-column justify-content-center" style={{
                        position: "absolute",
                        top: "-1px",
                        right: "-37px",
                        zIndex: 0,
                        backgroundColor: "#008FE2",
                        width: "35px",
                      }}>
                        {(tableProps && element.type === "basictable") && <>
                          <span
                            ref={popoverRef}
                            className="elementController"
                            style={{ borderBottom: "1px solid #ffffff" }}
                            onClick={(e) => { handleContextMenu(e) }}
                          >
                            <TbTableOptions style={{ fontSize: "16px", color: "#fff" }} />
                          </span>
                          {/* <span className="elementController" style={{ borderBottom: "1px solid #ffffff" }} onClick={() => { }}>
                            <RxGear style={{ fontSize: "16px", color: "#fff" }} />
                          </span> */}
                        </>}
                        <span className="elementController" style={{ borderBottom: "1px solid #ffffff" }} onClick={() => copyElement(element.id)}>
                          <PiCopySimpleThin style={{ fontSize: "16px", color: "#fff" }} />
                        </span>
                        <span className="elementController" onClick={() => deleteElement(element)}>
                          <MdOutlineDelete style={{ fontSize: "18px", color: "#fff" }} />
                        </span>
                      </div>
                    </>
                  )}
                </div>
              ))
            ) : (
              <div className="p-4">
                <h4 className="addLayoutSection text-center p-4">Add layout here</h4>
              </div>
            )}
          </DragListView>
        </div>
      </div>
      <Modal centered isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader style={{ borderBottom: "none" }}>Add Button</ModalHeader>
        <ModalBody style={{ paddingTop: "0rem" }}>
          <div className="d-flex align-items-center my-1 row" style={{ paddingTop: "5px" }}>
            <div className="col-4">
              <label
                className="mb-0"
                style={{
                  marginRight: "15px",
                  lineHeight: "38px",
                  fontSize: "15px"
                }}
              >
                Button Name
              </label>
            </div>
            <div className="col-8">
              <input
                type="text"
                className="form-control"
                placeholder="Please enter button name"
                value={buttonProps.btnName}
                onChange={(e) => setButtonProps((prevData) => ({ ...prevData, btnName: e.target.value }))}
                style={{
                  flex: 1,
                  border: "1px solid gainsboro",
                  outline: "none",
                  padding: "5px",
                  fontSize: "15px",
                  boxShadow: "none"
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-center row my-1" style={{ paddingTop: "5px" }}>
            <div className="col-4">
              <label
                className="mb-0"
                style={{
                  marginRight: "15px",
                  lineHeight: "38px",
                  fontSize: "15px"
                }}
              >
                Link Type
              </label>
            </div>
            <div className="col-8">

              <input
                type="text"
                className="form-control"
                placeholder="Enter alternative text here..."
                value={buttonProps.linkType}
                onChange={(e) => setButtonProps((prevData) => ({ ...prevData, linkType: e.target.value }))}
                style={{
                  flex: 1,
                  border: "1px solid gainsboro",
                  outline: "none",
                  padding: "5px",
                  fontSize: "15px",
                  boxShadow: "none"
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-center row my-1" style={{ paddingTop: "5px" }}>
            <div className="col-4">
              <label
                className="mb-0"
                style={{
                  marginRight: "15px",
                  lineHeight: "38px",
                  fontSize: "15px"
                }}
              >
                Button Link
              </label>
            </div>
            <div className="col-8">

              <input
                type="text"
                className="form-control"
                placeholder="Please enter a button link."
                value={buttonProps.btnLink}
                onChange={(e) => setButtonProps((prev) => ({ ...prev, btnLink: e.target.value }))}
                style={{
                  flex: 1,
                  border: "1px solid gainsboro",
                  outline: "none",
                  padding: "5px",
                  fontSize: "15px",
                  boxShadow: "none"
                }}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => { handleAddButton() }}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
      <Popover
        placement="bottom"
        isOpen={popoverOpen}
        target={popoverRef}
        toggle={togglePopover}
      // style={{ position: "absolute", top: contextMenuPosition.top, left: contextMenuPosition.left, zIndex: 1000 }}
      >
        <PopoverBody style={{ width: "200px" }}>
          <div className="tableColSetting">
            <div className="mb-1" style={{ fontWeight: "600" }} >INSERT ROW</div>
            <div className="d-flex align-items-center" style={{ gap: "5px" }}>
              <div className="buttonStyle" onClick={() => handleAction("insertRowAbove")}>
                Above
              </div>
              <div className="buttonStyle" onClick={() => handleAction("insertRowBelow")}>
                Below
              </div>
            </div>

            <div className="mt-2 mb-1" style={{ fontWeight: "600" }}>INSERT COLUMN</div>
            <div className="d-flex align-items-center" style={{ gap: "5px" }}>
              <div className="buttonStyle" onClick={() => handleAction("insertColumnBefore")}>
                Before
              </div>
              <div className="buttonStyle" onClick={() => handleAction("insertColumnAfter")}>
                After
              </div>
            </div>

            <div className="d-flex text-danger mt-2" style={{ justifyContent: "space-evenly" }}>
              <div className="text-danger fw-bold p-0" style={{ cursor: "pointer" }} onClick={() => handleAction("deleteRow")}>
                <MdDeleteOutline /> Row
              </div>
              <div className="text-danger fw-bold p-0" style={{ cursor: "pointer" }} onClick={() => handleAction("deleteColumn")}>
                <MdDeleteOutline /> Column
              </div>
            </div>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default CanvasComponent;
