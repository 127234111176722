import React, { useState, Fragment, useEffect, useRef } from "react";
import logo from "../../../assets/images/ipay-logo-40-40.png";
import Language from "./language";
import UserMenu from "./userMenu";
import Notification from "./notification";
import SearchHeader from "./searchHeader";
import { Link, useHistory } from "react-router-dom";
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import "./Header.css";
import { TabContent, TabPane, Nav, NavItem, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  AlignLeft,
  Maximize,
  Bell,
  MessageCircle,
  MoreHorizontal, Repeat
} from "react-feather";
import nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead';
import SearchBar from "./SearchBar";
import MasterSearchBarComp from "./MasterSearchBarComp";
import { mdiEye, mdiTrashCanOutline, mdiChartSankeyVariant, mdiCogOutline } from "@mdi/js"
import Icon from "@mdi/react";
import { useMediaQuery } from 'react-responsive';

const Header = (props) => {
  const [sidebar, setSidebar] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [headerbar, setHeaderbar] = useState(true);
  const [transferModal, setTransferModal] = useState(false);
  const [creditModal, setCreditModal] = useState(false);
  const [searchUserFilter, setSearchUserFilter] = useState("name");
  const [selectedReceiver, setSelectedReceiver] = useState(null);
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [ipay_pin, setIpay_pin] = useState("kFjvFxuMY5KOPaCvCaLWPs602XQ");
  const [from_id, setFrom_id] = useState(null);
  const [to_customer_id, setTo_customer_id] = useState("");
  const [to_walletType, setTo_walletType] = useState("");
  const [transf_amt, setTransf_amt] = useState("");
  const [additional_notes, setAdditional_notes] = useState("");
  const [for_message, setFor_message] = useState("");
  const [debitAmountModal, setDebitAmountModal] = useState(false);
  const [adminUser, setAdminuser] = useState(JSON.parse(localStorage.getItem("user")));
  const [countries, setcountries] = useState([]);
  let [loading, setloading] = useState(false);
  let [fetchFilterResultLoading, setfetchFilterResultLoading] = useState(false);
  let [accountType, setaccountType] = useState("merchant_name");
  let [filteredOptions, setfilteredOptions] = useState([]);
  const typeaheadRef = useRef(null);
  const transtypeheadRef = useRef(null);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });


  const { filteredUser, ipay_details } = props;
  React.useState(nprogress.start());

  let history = useHistory();

  React.useEffect(() => {
    nprogress.done();
    return () => nprogress.start();
  });

  useEffect(() => {
    setFrom_id(props.ipay_details ? props.ipay_details._id : "")
  }, [])

  useEffect(() => {
    // setFrom_id(props.ipay_details ? props.ipay_details._id : "")
    // console.log(filteredOptions, "filteredOptions")
  }, [filteredOptions])

  const moveElement = (array) => {

    let arrangeCountry = array;

    let australia = arrangeCountry.map((e) => e.country_code).indexOf('AU');
    if (australia != -1) {
      arrangeCountry.splice(0, 0, arrangeCountry.splice(australia, 1)[0]);
    }

    let japanIdx = arrangeCountry.map((e) => e.country_code).indexOf('JP');
    if (japanIdx != -1) {
      arrangeCountry.splice(1, 0, arrangeCountry.splice(japanIdx, 1)[0]);
    }

    let thailand = arrangeCountry.map((e) => e.country_code).indexOf('TH');
    if (thailand != -1) {
      arrangeCountry.splice(2, 0, arrangeCountry.splice(thailand, 1)[0]);
    }

    let unitedState = arrangeCountry.map((e) => e.country_code).indexOf('US');
    if (unitedState != -1) {
      arrangeCountry.splice(3, 0, arrangeCountry.splice(unitedState, 1)[0]);
    }

    // let india = arrangeCountry.map((e) => e.country_code).indexOf('IN');
    // if (india != -1) {
    // 	arrangeCountry.splice(3, 0, arrangeCountry.splice(india, 1)[0]);
    // }		
    return arrangeCountry;

  }




  useEffect(() => {

    fetchCountries();

  }, [])


  useEffect(() => {
    setFilteredUserData(filteredUser);

  }, [filteredUser]);

  useEffect(() => {
    setSelectedPayer(props.ipay_details);

  }, [ipay_details]);

  var handleSetCountry = (country_code, name) => {
    localStorage.setItem('sessionCountryCode', country_code);
    localStorage.setItem('sessionCountryName', name);
    // location.reload(); /
    window.location.reload();

  }

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.remove("open");
      document.querySelector(".sidebar1").classList.remove("open");
      //changes
      // document.querySelector(".page-body-above").style.width="70vw";
      document.querySelector(".page-body-above").style.marginLeft = "0px";
      document.querySelector(".page-body").style.marginLeft = "210px";
      document.querySelector(".page-body").style.transition = "0.8s";
    } else {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.add("open");
      document.querySelector(".sidebar1").classList.add("open");
      //changes
      // document.querySelector(".sidebar1").style.width="0px";
      document.querySelector(".page-body-above").style.marginLeft = "0px";
      document.querySelector(".page-body").style.marginLeft = "0px";
      document.querySelector(".page-body").style.transition = "0.8s";
    }
  };

  function showRightSidebar() {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".right-sidebar").classList.add("show");
    } else {
      setRightSidebar(!rightSidebar);
      document.querySelector(".right-sidebar").classList.remove("show");
    }
  }

  const toggleTransferModal = () => {
    setTransferModal(!transferModal)
    setAmtValidation(false)
    setTransf_amt("")
  }

  const toggleCreditModal = () => {
    setCreditModal(!creditModal)
    setAmtValidation(false)
    setTransf_amt("")
  }

  const toggleDebitAmountModal = () => {
    setDebitAmountModal(!debitAmountModal)
    setAmtValidation(false)
    setTransf_amt("")
  }

  const apiCall = async (text) => {
    return new Promise((resolve, reject) => {
      client.post(api.filterCustMerchData, { search_term: text, filter_type: accountType == "customer_name" ? 1 : 2 }, async function (error, response) {

        // console.log(response, "response")
        if (!error) {
          if (typeof response.error !== "undefined") {
            try {
              if (!response.error) {
                resolve(response.result);

              } else {
                reject("internal error")
              }
            } catch {
              reject("internal error")
            }
          }
        } else {
          reject("internal error")
        }

      })
    })
  }

  var fetchFilterResult = async (text) => {

    try {
      setfetchFilterResultLoading(true);
      let result = await apiCall(text)
      setfilteredOptions(result);
      setfetchFilterResultLoading(false);

    } catch {
      setfilteredOptions([]);
      setfetchFilterResultLoading(false);

    }

  }

  var fetchCountries = () => {
    setloading(true);
    client.post(api.fetch_country, { show_all: true }, async function (error, response) {
      // console.log(response)
      if (!error) {
        if (typeof response.error !== "undefined") {
          try {
            if (!response.error) {
              let allCountData = [...response.result.data];
              let arrangedCountry = await moveElement(allCountData);
              setcountries(arrangedCountry);
            }
          } catch {

          }
        }
      }
      setloading(false);
    });
  }

  const onDebitAmountSubmit = (event) => {
    event.preventDefault();

    let from_walletType_n;
    try {
      from_walletType_n = selectedReceiver[0].wallet[0].wallet_type;
    } catch (e) {
      // console.log(e)
      from_walletType_n = selectedReceiver[0].type;
    }

    let postData = {
      transfer_type: 'debit',
      credits: creditModal ? true : false,
      to_customer_id: "5f12aad64595d48863177243",
      from_customer_id: selectedReceiver[0]._id,
      display_data: {
        from_name: selectedReceiver[0].type == 2 ? selectedReceiver[0].business_name : selectedReceiver[0].displayName, from_mobile: selectedReceiver[0].mobile,
        to_name: "i-Pay Australia", to_mobile: ""
      },
      from_walletType: from_walletType_n,
      to_walletType: 2,
      description: `${for_message}`,
      payTotal: transf_amt,
      actual_pay_amount: transf_amt,
      pay_charges: 0,
      ipay_pin: "",
      request_token: "",
      reference: 3,
      flag_note: { type: "Debit amount", note: "Amount debited from customer account", user: adminUser.email, date: new Date() },

    };
    //console.log(postData);

    client.post(api.transfer_to_wallet, postData, (error, response) => {
      if (!error) {
        if (!response.error) {
          window.location.reload(false);
          toast.success("Amount debited");

        } else {
          console.log(response);
          setTimeout(() => {
            toast.error(response.message);
          }, 200);
        }
      } else {
        console.log("Internal error");
        setTimeout(() => {
          toast.error("Internal error occured. Please contact support");
        }, 200);
      }
    })
  }

  const [amtValidation, setAmtValidation] = useState(false)
  const setTransferAmt = (value) => {
    setTransf_amt(value)
    var reg = new RegExp(/^\d*\.?\d*$/)
    if (reg.test(value)) {
      setAmtValidation(false)
    } else {
      setAmtValidation(true)
    }
  }

  const renderDebitAmountModal = () => {
    return (
      <Modal isOpen={debitAmountModal} toggle={toggleDebitAmountModal} className="modal-body" centered={true}>
        <form className="theme-form" noValidate="" onSubmit={onDebitAmountSubmit}>
          <ModalHeader toggle={toggleDebitAmountModal}>Debit Amount</ModalHeader>
          <ModalBody>
            <div className="row" style={{ margin: "0" }}>
              {/* <div className="col">
                <div className="form-group col-l-6">
                  <label>From</label>
                  <select className="form-control" name="from" defaultValue="merchant_name" onChange={onToFilterSelect}>
                    <option value='customer_name'>Customer name </option>
                    <option value='merchant_name'>Store name</option>
                  </select>
                       <input className="form-control" type="text" name='' placeholder={'Store Name'} value={''} ></input>
                </div>
              </div> */}
              <div className="col">
                <div className={`form-group col-l-6 ${(!filteredUserData || filteredUserData.length <= 0) && `debit-amount`}`}>
                  <label>From</label>
                  <AsyncTypeahead
                    ref={transtypeheadRef}
                    id="public-typeahead"
                    labelKey={accountType == "customer_name" ? `name` : `business_name`}
                    filterBy={() => true}
                    onSearch={term => {
                      // console.log(term, "term")
                      fetchFilterResult(term);
                      // setValue(term);
                    }}
                    onChange={(res) => {

                      // console.log(event)
                      handleChange(res)

                    }
                    }
                    placeholder="Enter Store Name"
                    isLoading={fetchFilterResultLoading}
                    options={filteredOptions}
                  />
                </div>
              </div>
            </div>
            <div className="row receiver" style={{ marginBottom: "15px" }}>
              <div className="col-md-12">
                {selectedReceiver && renderReceiverDetails()}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group col-md-12 ">
                  <label>Debit Amount</label>
                  {/* <input className="form-control" type="text" name='transf_amt' value={transf_amt} onChange={(event) => setTransf_amt(event.target.value)}></input> */}
                  <input className="form-control" type="text" name='transf_amt' value={transf_amt} onChange={(event) => setTransferAmt(event.target.value)}></input>
                </div>
                {amtValidation && transf_amt != "" &&
                  <div style={{ color: 'red', margin: '15px', marginTop: '-15px' }}>
                    * Enter valid number (eg: 100 or 100.50)
                  </div>
                }
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group col-md-12 ">
                  <label>Reason</label>
                  <input className="form-control" required type="text" name='for_message' value={for_message} onChange={(event) => setFor_message(event.target.value)} ></input>
                </div>
              </div>
            </div>

          </ModalBody>
          <ModalFooter>
            <Button color="default" onClick={toggleDebitAmountModal}>Not sure!</Button>
            <Button color="primary" type="submit" disabled={amtValidation}>Yes, Please!</Button>
          </ModalFooter>
        </form>
      </Modal>
    )
  }


  const renderTransferModal = () => {
    return (
      <Modal isOpen={transferModal} toggle={toggleTransferModal} className="modal-body" centered={true}>
        <form className="theme-form" noValidate="" onSubmit={transfer_amt}>
          <ModalHeader toggle={toggleTransferModal}>Transfer Money</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col">
                <div className="form-group col-md-12 ">
                  <label>From</label>
                  <select className="form-control" name="from_id" value={from_id} onChange={changeHandler} >
                    <option value='5f12aad64595d48863177243'>i-Pay Australia, Balance Remaining ${props.ipay_wallet_amt.toFixed(2)}</option>
                    <option value='5f11e6b812caca165455b085'>Speed Food,  Balance Remaining ${props.speed_food_wallet_amt.toFixed(2)}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row" style={{ margin: "0" }}>
              {/* <div className="col">
                <div className="form-group col-l-6">
                  <label>To</label>
                    <select className="form-control accountSelect" name="from" defaultValue="merchant_name" onChange={(event) => {
                    setaccountType(event.target.value)
                  }}>
                    <option value='customer_name'>Customer name </option>
                    <option value='merchant_name'>Store name</option>
                  </select>
                  <input className="form-control" type="text" name='' placeholder={'Store Name'} value={''} ></input>
                </div>
              </div> */}
              <div className="col">
                <div className="form-group col-l-6 ">
                  <label>To</label>
                  {/* <Typeahead
                    id="public-typeahead"
                    defaultSelected={props.userdata.slice(0, 0)}
                    labelKey={searchUserFilter}
                    options={filteredUserData}
                    placeholder="User detail"
                    onChange={handleChange}
                  /> */}
                  <AsyncTypeahead
                    ref={transtypeheadRef}
                    id="public-typeahead"
                    labelKey={accountType == "customer_name" ? `name` : `business_name`}
                    filterBy={() => true}
                    onSearch={term => {
                      // console.log(term, "term")
                      fetchFilterResult(term);
                      // setValue(term);
                    }}
                    onChange={(res) => {

                      // console.log(event)
                      handleChange(res)

                    }
                    }
                    placeholder="Enter Store Name"
                    isLoading={fetchFilterResultLoading}
                    options={filteredOptions}
                  />
                </div>
              </div>
            </div>
            <div className="row receiver" style={{ marginBottom: "15px" }}>
              <div className="col-md-12">
                {selectedReceiver && renderReceiverDetails()}
              </div>

            </div>
            <div className="row">
              <div className="col">
                <div className="form-group col-md-12 ">
                  <label>Amount</label>
                  {/* <input className="form-control" type="text" name='transf_amt' value={transf_amt} onChange={(event) => setTransf_amt(event.target.value)}></input> */}
                  <input className="form-control" type="text" name='transf_amt' value={transf_amt} onChange={(event) => setTransferAmt(event.target.value)}></input>
                </div>
                {amtValidation && transf_amt != "" &&
                  <div style={{ color: 'red', margin: '15px', marginTop: '-15px' }}>
                    * Enter valid number (eg: 100 or 100.50)
                  </div>
                }
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group col-md-12 ">
                  <label>Message(<span style={{ color: "red" }}>*Note:User will be able to view it in app</span>)</label>
                  <input className="form-control" required type="text" name='for_message' value={for_message} onChange={(event) => setFor_message(event.target.value)} ></input>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group col-md-12 ">
                  <label>Additional Notes</label>
                  <input className="form-control" type="text" name='additional_notes' value={additional_notes} onChange={(event) => setAdditional_notes(event.target.value)} ></input>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="default" onClick={toggleTransferModal}>Not sure!</Button>
            <Button color="primary" type="submit" disabled={amtValidation}>Yes, Please!</Button>
          </ModalFooter>
        </form>
      </Modal>

    );
  }

  const renderCreditModal = () => {
    return (
      <Modal isOpen={creditModal} toggle={toggleCreditModal} className="modal-body" centered={true}>
        <form className="theme-form" noValidate="" onSubmit={transfer_amt}>
          <ModalHeader toggle={toggleCreditModal}>Transfer Credits Balance</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col">
                <div className="form-group col-md-12 ">
                  <label>From</label>
                  <select className="form-control" name="from_id" value={from_id} onChange={changeHandler} >
                    <option value='5f12aad64595d48863177243'>i-Pay Australia, Balance Remaining ${props.ipay_wallet_amt.toFixed(2)}</option>
                    <option value='5f11e6b812caca165455b085'>Speed Food,  Balance Remaining ${props.speed_food_wallet_amt.toFixed(2)}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row" style={{ margin: "0" }}>
              {/* <div className="col">
                <div className="form-group col-l-6">
                  <label>To</label>
                  <select className="form-control accountSelect" name="from" defaultValue="merchant_name" onChange={(event) => {
                    setaccountType(event.target.value)
                  }}>
                    <option value='customer_name'>Customer name </option>
                    <option value='merchant_name'>Store name</option>
                  </select>
                   <input className="form-control" type="text" name='' placeholder={'Store Name'} value={''} ></input>
                </div>
              </div> */}
              <div className="col">
                <div className="form-group col-l-6 ">
                  <label>To</label>
                  {/* <Typeahead
                    id="public-typeahead"
                    defaultSelected={props.userdata.slice(0, 0)}
                    labelKey={searchUserFilter}
                    options={filteredUserData}
                    placeholder="User detail"
                    onChange={handleChange}
                  /> */}
                  <AsyncTypeahead
                    ref={typeaheadRef}
                    id="public-typeahead"
                    labelKey={accountType == "customer_name" ? `name` : `business_name`}
                    filterBy={() => true}
                    onSearch={term => {
                      // console.log(term, "term")
                      fetchFilterResult(term);
                      // setValue(term);
                    }}
                    onChange={(res) => {

                      // console.log(event)
                      handleChange(res)

                    }
                    }
                    placeholder="Enter Store Name"
                    isLoading={fetchFilterResultLoading}
                    options={filteredOptions}
                  />
                </div>
              </div>
            </div>
            <div className="row receiver" style={{ marginBottom: "15px" }}>
              <div className="col-md-12">
                {selectedReceiver && renderCreditReceiverDetails()}
              </div>

            </div>
            <div className="row">
              <div className="col">
                <div className="form-group col-md-12 ">
                  <label>Amount</label>
                  {/* <input className="form-control" type="text" name='transf_amt' value={transf_amt} onChange={(event) => setTransf_amt(event.target.value)}></input> */}
                  <input className="form-control" type="text" name='transf_amt' value={transf_amt} onChange={(event) => setTransferAmt(event.target.value)}></input>
                </div>
                {amtValidation && transf_amt != "" &&
                  <div style={{ color: 'red', margin: '15px', marginTop: '-15px' }}>
                    * Enter valid number (eg: 100 or 100.50)
                  </div>
                }
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group col-md-12 ">
                  <label>Message(<span style={{ color: "red" }}>*Note:User will be able to view it in app</span>)</label>
                  <input className="form-control" type="text" required name='for_message' value={for_message} onChange={(event) => setFor_message(event.target.value)} ></input>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group col-md-12 ">
                  <label>Additional Notes</label>
                  <input className="form-control" type="text" name='additional_notes' value={additional_notes} onChange={(event) => setAdditional_notes(event.target.value)} ></input>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="default" onClick={toggleTransferModal}>Not sure!</Button>
            <Button color="primary" type="submit" disabled={amtValidation}>Yes, Please!</Button>
          </ModalFooter>
        </form>
      </Modal>

    );
  }

  const changeHandler = (e) => {
    console.log(e.target.value);

    //this.setState({ [e.target.name]: e.target.value });
    setFrom_id(e.target.value)
    if (e.target.value == '5f12aad64595d48863177243') {
      setIpay_pin("kFjvFxuMY5KOPaCvCaLWPs602XQ=");
      console.log(props.ipay_details);
      setSelectedPayer(props.ipay_details)
    } else if (e.target.value == '5f11e6b812caca165455b085') {
      setIpay_pin("fdHH3Xy/zax/0Nl5E8IqdepcXQA=");
      console.log(props.speedFood_details);
      setSelectedPayer(props.speedFood_details)
    }
  }

  const handleChange = async (selectedOptions) => {
    setSelectedReceiver(selectedOptions)
  }

  // console.log(selectedPayer);
  // console.log(selectedReceiver);

  const transfer_amt = (event) => {
    event.preventDefault();

    let postData = {
      transfer_type: 'credit',
      credits: creditModal ? true : false,
      to_customer_id: selectedReceiver[0]._id,
      from_customer_id: from_id,
      display_data: {
        from_name: selectedPayer.business_name, from_mobile: selectedPayer.mobile,
        to_name: selectedReceiver[0].type == 2 ? selectedReceiver[0].business_name : selectedReceiver[0].displayName, to_mobile: selectedReceiver[0].mobile
      },
      from_walletType: 2,
      //to_walletType: this.state.to_walletType, //  depends upon user selected
      to_walletType: selectedReceiver[0].type,
      description: for_message,
      payTotal: transf_amt,
      actual_pay_amount: transf_amt,
      pay_charges: 0,
      ipay_pin: ipay_pin,
      flag_note: { type: "normal", note: additional_notes, user: adminUser.email, date: new Date() },
      request_token: "",
    };

    console.log(postData);
    client.post(api.transfer_to_wallet, postData, (error, response) => {
      if (!error) {
        if (!response.error) {
          console.log(response);
          //window.location.reload(false);
          toast.success(response.message);
          toggleCreditModal();
          setSelectedReceiver(null);
          setAmtValidation(false);
          setTransf_amt("");
          setFor_message("");
          setfilteredOptions([]);
          setfetchFilterResultLoading(false);
          if (postData.credits) {
            typeaheadRef.current.getInstance().clear();
          } else {
            transtypeheadRef.current.getInstance().clear();
          }
        } else {
          console.log(response);
          setTimeout(() => {
            toast.error(response.message);
          }, 200);
        }
      } else {
        console.log(error);
        setTimeout(() => {
          toast.error("Internal error occured. Please contact support");
        }, 200);
      }
    })
  }

  const renderCreditReceiverDetails = () => {
    return (
      <div className="table-responsive">
        <table className="table table-border-horizontal table-striped">
          <thead>
            <tr >
              <th scope="col" >#</th>
              <th scope="col" >Name</th>
              <th scope="col" >i-Pay ID</th>
              <th scope="col" >Email</th>
              <th scope="col" >Mobile</th>
              <th scope="col" >Balance</th>
            </tr>
          </thead>
          <tbody>
            {selectedReceiver.map((item, index) => {
              return (
                <tr >
                  <th scope="row" >{index + 1}</th>
                  <td >{item.type == 2 ? item.business_name : item.name}</td>
                  <td >{item.ipay_id}</td>
                  <td >{item.email}</td>
                  <td >{item.mobile}</td>
                  <td >{item.country.currency_symbol}{item.wallet[0].credit_balance ? item.wallet[0].credit_balance.toFixed(2) : 0.00}</td>
                </tr>

              );
            })}


          </tbody>
        </table>
      </div>
    )
  }

  const renderReceiverDetails = () => {
    return (
      <div className="table-responsive">
        <table className="table table-border-horizontal table-striped">
          <thead>
            <tr >
              <th scope="col" >#</th>
              <th scope="col" >Name</th>
              <th scope="col" >i-Pay ID</th>
              <th scope="col" >Email</th>
              <th scope="col" >Mobile</th>
              <th scope="col" >Balance</th>
            </tr>
          </thead>
          <tbody>
            {selectedReceiver.map((item, index) => {
              return (
                <tr >
                  <th scope="row" >{index + 1}</th>
                  <td >{item.type == 2 ? item.business_name : item.name}</td>
                  <td >{item.ipay_id}</td>
                  <td >{item.email}</td>
                  <td >{item.mobile}</td>
                  <td >{item.country.currency_symbol}{item.wallet[0].wallet_amount.toFixed(2)}</td>
                </tr>

              );
            })}


          </tbody>
        </table>
      </div>
    )
  }

  const onToFilterSelect = (event) => {
    let updatedData = [];
    setSelectedReceiver([]);

    if (event.target.value == "mobile") {
      const filteredUserData = props.userdata.map(el => {
        return el
      });
      setSearchUserFilter("mobile");
      setFilteredUserData(filteredUserData)
    } else if (event.target.value == "email") {
      const filteredUserData = props.userdata.map(el => {
        return el
      });
      setSearchUserFilter("email");
      setFilteredUserData(filteredUserData)
    } else if (event.target.value == "customer_name") {
      const filteredUserData = props.userdata.filter(el => {
        return (el.type == 1 && el.status == 1) || (el.type == 1 && el.status == 2)
      })
      setSearchUserFilter("name");
      setFilteredUserData(filteredUserData)
    } else if (event.target.value == "merchant_name") {
      // const filteredUserData = props.userdata.filter(el => {
      //   return (el.type == 2 && el.status == 1 && el.mobile_status == 1) || (el.type == 2 && el.status == 5 && el.mobile_status == 1)
      // })
      const filteredUserData = props.merchantData.filter(el => el.business_name?.length >= 1);
      setSearchUserFilter("business_name");
      setFilteredUserData(filteredUserData)
    } else {
      setSearchUserFilter("");

    }

    //console.log(searchUserFilter);
  }

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  let getSessionCountry = localStorage.getItem("sessionCountryCode");


  return (
    <Fragment>
      {renderTransferModal()}
      {renderCreditModal()}
      {renderDebitAmountModal()}
      <div className="page-main-header">
        <div className="main-header-right row">
          <div className="main-header-left d-none">
            <div className="logo-wrapper">
              {/* <Link to="/dashboard/default">
                <img className="img-fluid" src={logo} alt="" />
              </Link> */}
            </div>
          </div>

          <div className="nav-right col p-0">
            <ul className={`nav-menus ${headerbar ? "" : "open"}`}>
              <li><MasterSearchBarComp /></li>
              <li className="d-flex">
                {countries.length > 0 && countries.slice(0, 4).map((currItem) => {
                  return (
                    <li
                      className="mr-2"
                      style={{ border: "1px solid #fff", padding: "0px 10px", borderRadius: "5px", backgroundColor: currItem.country_code == getSessionCountry ? "#fff" : "transparent" }}
                      onClick={() => { handleSetCountry(currItem.country_code, currItem.country_name) }}
                    >
                      <a className="txt-dark" href="#javascript">
                        <h6 style={{ fontSize: "14px", margin: "5px 0px", color: currItem.country_code == getSessionCountry ? '#0091DD' : "#fff" }}>
                          {currItem.country_code == "AU" ? "Aus" : currItem.country_code == "JP" ? "JP" : currItem.country_code == "TH" ? "Thai" : currItem.country_code == "US" ? "US" : ''}
                        </h6>
                      </a>
                    </li>
                  )
                })}
                <li className="onhover-dropdown" style={{ border: "1px solid #fff", padding: "0px 10px", borderRadius: "5px" }}>
                  <a className="txt-dark" href="#javascript">
                    {/* {<h6 style={{ fontSize: "14px" }}>{localStorage.getItem("sessionCountryName")}</h6>} */}
                    {<h6 style={{ fontSize: "14px", margin: "5px 0px" }}>Others</h6>}
                  </a>
                  {<div>
                    {/* <div className="language-dropdown onhover-show-div row" style={{ width: window.innerWidth - 400, maxHeight: window.innerHeight - 100, overflowY: "scroll", left: 235, position: "fixed", border: "2px solid #e7e7e7",zIndex:25 }}> */}
                    <div className="language-dropdown onhover-show-div row" style={{ width: window.innerWidth - 400, maxHeight: window.innerHeight - 100, overflowY: "scroll", left: 290, position: "fixed", top: 70, border: "2px solid #e7e7e7", zIndex: 25 }}>

                      {countries && countries.slice(4, countries.length).map((item) => {
                        return (
                          <p className='list-group-item-action col-md-3 p-2 mb-0' style={{ fontSize: "14px" }} onClick={() => handleSetCountry(item.country_code, item.country_name)}>
                            {item.country_flag} &nbsp; {item.country_name}
                          </p>
                        )
                      })}

                    </div>
                  </div>}
                </li>
              </li>
              <li>
                <button className="transferbtn" onClick={toggleTransferModal} id="transfer_money">
                  <i className="icofont icofont-exchange"></i>
                </button>
                <UncontrolledTooltip
                  placement="bottom"
                  target="transfer_money"
                >
                  Transfer Money
                </UncontrolledTooltip>
              </li>
              <li>
                <button className="transferbtn" onClick={toggleCreditModal} id="transfer_credits">
                  <i className="icofont icofont-copyright"></i>
                </button>
                <UncontrolledTooltip
                  placement="bottom"
                  target="transfer_credits"
                >
                  Transfer Credits
                </UncontrolledTooltip>
              </li>
              <li>
                <button className="transferbtn" onClick={toggleDebitAmountModal} id="charge_user">
                  <i className="icofont icofont-cur-dollar-minus"></i>
                </button>
                <UncontrolledTooltip
                  placement="bottom"
                  target="charge_user"
                >
                  Charge User
                </UncontrolledTooltip>
              </li>
              <li style={{ border: '0px' }}>
                <a onClick={goFull} className="text-dark icnHearder" href="#!">
                  {<Maximize style={{ width: "18px", height: "18px" }} />}
                </a>
              </li>
              {/* <li className="onhover-dropdown">
                <a
                  className="txt-dark icnHearder"
                  href=""
                  onClick={evnt => {
                    evnt.preventDefault()
                    history.push({ pathname: `/merchants/trash_merchant_requests` })
                  }}
                >
                  {<h6 style={{ fontSize: "14px", marginTop: '13px' }}>EN</h6>}

                  <Icon
                    path={mdiTrashCanOutline}
                    size={1}
                    color="white"
                  />
                </a>
                {<Language />}
              </li> */}
              <li className="onhover-dropdown">
                <div className="txt-dark icnHearder">
                  {<Notification style={{ width: "14px", height: "14px" }} />}
                  {<Bell style={{ width: "18px", height: "18px" }} />}
                  {<span className="dot"></span>}
                  {<Notification style={{ width: "14px", height: "14px" }} />}
                </div>
              </li>
              {/* <li>
                {<a className="icnHearder" onClick={showRightSidebar}>
                  <MessageCircle style={{ width: "18px", height: "18px" }} />
                  <span className="dot"></span>
                </a>}
              </li> */}
              <UserMenu />
            </ul>
            <div
              className="d-lg-none mobile-toggle pull-right"
              onClick={() => setHeaderbar(!headerbar)}
            >
              <MoreHorizontal />
            </div>
          </div>
          <script id="result-template" type="text/x-handlebars-template">
            <div className="ProfileCard u-cf">
              <div className="ProfileCard-avatar">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-airplay m-0"
                >
                  <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                  <polygon points="12 15 17 21 7 21 12 15"></polygon>
                </svg>
              </div>
              <div className="ProfileCard-details">
                <div className="ProfileCard-realName"></div>
              </div>
            </div>
          </script>
          <script id="empty-template" type="text/x-handlebars-template">
            <div className="EmptyMessage">
              Your search turned up 0 results. This most likely means the
              backend is down, yikes!
            </div>
          </script>
        </div>
      </div>
    </Fragment>
  );
};
export default Header;
