import React, { Fragment, useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import client from "../../../Api/HTTPClient";
import api from "../../../constant/apilist";
import { ToastContainer, toast } from "react-toastify";
import { Link as NavLink } from "react-router-dom";
import { DisappearedLoading } from "react-loadingg";
import useForm from 'react-hook-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink as NavLinkTab, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { FaList } from "react-icons/fa";
import { TfiLayoutGrid3Alt } from "react-icons/tfi";
import { CiEdit } from "react-icons/ci";
import { DollarSign, MapPin, X, TrendingDown, ArrowUp, ShoppingCart, Search, Activity, User, Users, Book } from 'react-feather';
import Slider from 'react-slick';
import CountUp from 'react-countup';
import RightBar from '../../../components/social-app/rightBar';
import GridViewTemplateCard from "./GridViewTemplateCard";
import { MdClose } from "react-icons/md";
// import customMultiSelect from "./selectComp/CustomOptionsMultiSelect";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap';
import { TiArrowSortedDown } from "react-icons/ti";
import { useHistory } from "react-router-dom";

import AddTemplateCategoryModal from './AddTemplateCategoryModal';

var Knob = require('knob')// browserify require
var primary = localStorage.getItem('primary_color') || '#327ebc';


const EmailTemplates = (props) => {

    const history = useHistory();

    const [modal, setModal] = useState();
    const [delete_modal, set_delete_toggle] = useState();
    const [del_templ_id, set_del_templ_id] = useState("");
    const [access_level, setAccessLevel] = useState(localStorage.getItem("accessLevel"))
    let { del_temp_id } = '';

    const [getError, setGetError] = useState(false);
    const [errorsMsg, setErrorsMsg] = useState('');
    const [templ_name, set_templ_Name] = useState("");
    const [templ_user_type, set_templ_user_type] = useState("");
    const [mailUniqueId, setMailUniqueId] = useState('');
    const [templ_test_mail, set_templ_test_mail] = useState("");
    const [templ_from, set_templ_from] = useState("");
    const [templ_subject, set_templ_subject] = useState("");
    const [templ_layout, set_templ_layout] = useState("");
    const [templ_comments, set_templ_comments] = useState("");
    const [activeTab, setActiveTab] = useState('2');
    const [btnloader, setBtnloader] = useState(false);
    const [searchData, setSearchData] = useState('');
    let [template_data, set_template_data] = useState(null);
    const [templatesData, setTemplateData] = useState([]);

    // console.log(template_data, "template_data");
    const toggle = () => { setModal(!modal) };
    const delete_toggle = () => { set_delete_toggle(!delete_modal) };
    const set_del_id = (temp_id) => { set_del_templ_id(temp_id) };

    const [settings, setSettings] = useState({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        // variableWidth:true,
        arrows: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 370,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    const { errors } = useForm(); // initialise the hook
    const [loading, setloading] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateChangeData, setTemplateChangeData] = useState({});
    const [viewType, setViewType] = useState('listView');
    const [catModal, setCatModal] = useState(false);
    const [catName, setCateName] = useState('');
    const [catError, setCatError] = useState(false);
    const [catgoryData, setCategoryData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({ title_name: 'all' });

    // console.log(catgoryData, "catgoryData");


    const toggleCatModal = () => { setCatModal(!catModal) }

    const handleSubmit = (e, data) => {
        e.preventDefault();
        let getIndex = 0;
        data = {
            templ_name: templ_name,
            templ_user_type: templ_user_type,
            templ_test_mail: templ_test_mail,
            templ_from: templ_from,
            templ_subject: templ_subject,
            templ_layout: '',
            templ_comments: templ_comments,
            auto_reminder: false,
            templ_unique_id: mailUniqueId
        };

        getIndex = template_data.findIndex(val => val.templ_unique_id == mailUniqueId);

        // setBtnloader(true);
        if (data !== '' && mailUniqueId !== '' && !getError) {
            if (getIndex == -1) {
                // client.post(api.add_new_mail_template, data, async function (error, response) {
                //     if (!error) {
                //         if (typeof response.error !== "undefined") {
                //             try {
                //                 if (!response.error) {
                //                     toast.success(response.message);
                //                     props.toggle();
                //                     refreshPage();
                //                     setBtnloader(false)
                //                 } else {
                //                     toast.error(response.message);
                //                     setBtnloader(false)
                //                 }
                //             } catch (e) {
                //                 toast.error("Internal error occured. Please contact support");
                //                 setBtnloader(false)

                //             }
                //         } else {
                //             toast.error("Internal error occured. Please contact support");
                //             setBtnloader(false)
                //         }
                //     } else {
                //         toast.error("Internal error occured. Please contact support");
                //         setBtnloader(false)
                //     }

                // });
                history.push('/settings/add_new_mail_template', { state: data });
            } else {
                setGetError(true);
                setErrorsMsg("This unique id is already exist!!");
            }
        } else {
            // errors.showMessages();
            setGetError(true);
            setErrorsMsg("Please Enter Unique Id!!");
        }

    }

    const handleDeleteSubmit = (e, templ_id) => {
        e.preventDefault();
        //alert(`Submitting Name ${templ_name}`)
        let data = { template_id: templ_id };
        setBtnloader(true)
        if (data !== '') {
            client.post(api.delete_mail_template, data, async function (error, response) {
                if (!error) {
                    if (typeof response.error !== "undefined") {
                        try {
                            if (!response.error) {
                                toast.success("Template Deleted Successfully");
                                refreshPage();
                                delete_toggle();
                                setBtnloader(false)
                            } else {
                                toast.error(response.message);
                                setBtnloader(false)
                            }
                        } catch (e) {
                            toast.error("Internal error occured. Please contact support");
                            setBtnloader(false)
                        }
                    } else {
                        toast.error("Internal error occured. Please contact support");
                        setBtnloader(false)
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                    setBtnloader(false)
                }

            });
        } else {
            errors.showMessages();
        }

    }

    const geta_all_templates = async () => {
        let postdata = { auto_reminder: false };
        let current = this;
        setloading(true);
        client.post(api.get_all_mail_templates, postdata, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            set_template_data(response.result.data);
                            setTemplateData(response.result.data);
                            setloading(false);

                        } else {
                            toast.error(response.message);
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }

        });
    };

    useEffect(() => {
        geta_all_templates();
    }, []);

    const refreshPage = () => {
        geta_all_templates();

    }

    const toggleupdateModal = () => {
        setUpdateModal(!updateModal)
    }

    const onUpdateTemplate = (event) => {
        event.preventDefault();
        let $ = [...template_data]?.map(e => {
            delete e.templ_layout;
            return e;
        })
        const postdata = $;
        setBtnloader(true)
        client.post(api.update_many_template, postdata, async function (error, response) {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            toast.success(response.message);
                            refreshPage()
                            setUpdateModal(!updateModal)
                            setBtnloader(false)
                        } else {
                            toast.error(response.message);
                            setBtnloader(false)
                        }
                    } catch (e) {
                        toast.error("Internal error occured. Please contact support");
                        setBtnloader(false)
                    }
                } else {
                    toast.error("Internal error occured. Please contact support");
                    setBtnloader(false)
                }
            } else {
                toast.error("Internal error occured. Please contact support");
                setBtnloader(false)
            }

        });

    }

    const renderUpdateModal = () => {
        return (
            <Modal isOpen={updateModal} toggle={toggleupdateModal} className="modal-body" centered={true}>
                <form className="theme-form" onSubmit={onUpdateTemplate}>
                    <ModalHeader>Update Changes</ModalHeader>
                    <ModalBody>
                        <h6>Do you really want to update the changes ?</h6>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={toggleupdateModal}>Not sure!</Button>
                        <Button color="primary" type="submit" disabled={btnloader}>Yes, Please!</Button>
                    </ModalFooter>
                </form>
            </Modal>

        );
    }

    const onChangeMultipleTemplate = (event, id) => {
        let { name, value } = event.target;
        if (name == "showInPromotionList") {
            value = event.target.checked
        }
        const updatedTemplateData = [...template_data]
        const template = updatedTemplateData.find(el => {
            return el._id == id
        });
        const templateIndex = updatedTemplateData.findIndex(el => {
            return el._id == id
        });
        const updatedTemplate = {
            ...template,
            [name]: value
        }
        updatedTemplateData[templateIndex] = updatedTemplate;
        set_template_data(updatedTemplateData);
        setTemplateData(updatedTemplateData);
    }


    useEffect(() => {
        if (searchData != "") {
            const handler = setTimeout(() => {
                const filteredData = template_data.filter((item) => item.templ_name.toLowerCase().includes(searchData.toLowerCase()));
                set_template_data(filteredData);
            }, 800);

            return () => { clearTimeout(handler) };
        } else {
            set_template_data(templatesData);
        }

    }, [searchData]);

    const [openRow, setOpenRow] = useState(null); // Track which row's dropdown is open
    const [selectedOptions, setSelectedOptions] = useState([]); // Track selected options per row
    const [selectedData, setSelectedData] = useState(null);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const togglePopover = () => { setPopoverOpen(!popoverOpen) };
    const popoverRef = useRef(null);
    // console.log(selectedOptions, "selectedOptions");
    // console.log(selectedData, "selectedData");
    // console.log(openRow, "openRow");



    // const handleClickOutside = (event) => {
    //     if (popoverRef.current && !popoverRef.current.contains(event.target)) {
    //         alert("pop close");
    //         // toggleDropdown(null, null, "close popup");
    //     }
    // };

    // useEffect(() => {
    //     if (popoverOpen) {
    //         document.addEventListener('click', handleClickOutside);
    //     } else {
    //         document.removeEventListener('click', handleClickOutside);
    //     }
    //     return () => {
    //         document.removeEventListener('click', handleClickOutside);
    //     };
    // }, [popoverOpen]);

    // console.log(openRow, "openRow=======?.");
    // console.log(catgoryData, "catgoryData");
    // console.log(selectedOptions, "selectedOptions");


    const updateTempCatRef = async () => {
        console.log(selectedData, "selectedData");
        let postData = { update_id: selectedData._id };

        if (selectedData && selectedData.hasOwnProperty('temlate_category') && selectedData.temlate_category.length > 0) {
            let getData = [...selectedData.temlate_category];
            let getArray = [];

            catgoryData.forEach((curElem) => {
                const index = selectedOptions.findIndex((obj) => obj.hasOwnProperty(curElem.title_name));
                const prevData = getData.findIndex((obj) => obj.hasOwnProperty(curElem.title_name));

                if (prevData == -1 && index !== -1 || prevData !== -1 && index !== -1) {
                    getArray.push(selectedOptions[index]);
                } else {
                    getArray.push({ [curElem.title_name]: false });
                }

            })
            postData.templateCategory = getArray;

        } else {

            let getArray = [];

            catgoryData.forEach((curElem) => {
                const index = selectedOptions.findIndex((obj) => obj.hasOwnProperty(curElem.title_name));
                if (index !== -1) {
                    getArray.push(selectedOptions[index]);
                } else {
                    getArray.push({ [curElem.title_name]: false });
                }

            })
            postData.templateCategory = getArray;
        }

        console.log(postData, "postData");

        client.post(api.update_cate_mail_template, postData, async function (error, response) {
            console.log(response, "response");
            if (!error) {
                if (!response.error) {
                    refreshPage();
                    setSelectedOptions([]);
                    setSelectedData();
                } else {
                    toast.error(response.message);
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
        });

    }

    useEffect(() => {
        if (!popoverOpen) {
            if (selectedOptions.length > 0) {
                updateTempCatRef();
            }
            // alert("Popover closed! Updating data...");
            // setData("Updated Data"); // Simulate a data update
        } else {
            setSelectedOptions([]);
            setSelectedData(null);
        }
    }, [popoverOpen]);

    const toggleDropdown = (rowId, templates, ref) => {
        // console.log(rowId, "rowId");
        // console.log(ref, "refrene");
        if (openRow == rowId) {
            setOpenRow(null);
            setPopoverOpen(false);
        } else {
            let c_ = templates.find((curD) => curD._id == rowId);
            setSelectedData(c_);
            setOpenRow(rowId);
            setPopoverOpen(true);
        }
    }

    const handleCheckboxChange = (rowId, key, value) => {

        setSelectedOptions((prev) => {

            const index = prev.findIndex((obj) => obj.hasOwnProperty(key));
            if (index !== -1) {
                const updatedData = [...prev];
                updatedData[index][key] = value;
                return updatedData;
            } else {
                return [...prev, { [key]: value }];
            }
        });
    };

    const rendertable = (template_type_check) => {

        let templates = [];

        if (selectedCategory.title_name == "all") {
            templates = template_data.filter((item) => (template_type_check == item.templ_user_type));
        } else if (selectedCategory.title_name !== "all") {
            templates = template_data.filter((item) => (template_type_check == item.templ_user_type && item.temlate_category.some((currD) => currD.hasOwnProperty(selectedCategory.title_name))));
        } else {
            templates = [];
        }
        // console.log(templates, "templates");

        return (
            !loading ?
                <Fragment>
                    {
                        viewType == "gridView" ? <>
                            <div className="row">
                                {templates.map((item, index) => {
                                    return (
                                        <GridViewTemplateCard
                                            item={item}
                                            index={index}
                                            access_level={access_level}
                                            delete_toggle={delete_toggle}
                                            set_del_id={set_del_id}
                                            onChangeMultipleTemplate={onChangeMultipleTemplate}
                                        />
                                    )
                                })}
                            </div>
                        </> : <>
                            {
                                templates.length > 0 ? <div className="table-responsive">
                                    <table className="table table-border-horizontal">
                                        <thead>
                                            <tr style={{ background: '#f9fafe' }}>
                                                <th scope="col">#</th>
                                                <th scope="col">Unique Id</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Test mails</th>
                                                {/* <th scope="col">From</th> */}
                                                <th scope="col">Subject</th>
                                                <th scope="col">Comments</th>
                                                <th scope="col" style={{ minWidth: "80px" }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {templates.map((item, index) => {

                                                let templateCateData = [];

                                                // if (catgoryData && catgoryData.length > 0) {
                                                //     catgoryData.map((currElem) => {
                                                //         // templateCateData.push({ `${currElem.title_name}`:false })
                                                //     })
                                                // }

                                                return (
                                                    <tr>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>
                                                            <input type="text" name="templ_unique_id" onChange={(event) => onChangeMultipleTemplate(event, item._id)} value={item.templ_unique_id} disabled={access_level == 1 ? true : false} />
                                                        </td>
                                                        <td>
                                                            <textarea className="form-control" rows="1" onChange={(event) => onChangeMultipleTemplate(event, item._id)}
                                                                value={item.templ_name} name="templ_name" disabled={access_level == 1 ? true : false}>
                                                            </textarea>
                                                        </td>
                                                        <td>
                                                            <textarea className="form-control" rows="1" onChange={(event) => onChangeMultipleTemplate(event, item._id)}
                                                                value={item.templ_test_mail} name="templ_test_mail" disabled={access_level == 1 ? true : false}></textarea></td>
                                                        {/* <td><textarea className="form-control" rows="1" onChange={(event) => onChangeMultipleTemplate(event, item._id)}
                                                       value={item.templ_from} name="templ_from" disabled={access_level == 1 ? true : false}></textarea></td> */}
                                                        <td><textarea className="form-control" rows="1" onChange={(event) => onChangeMultipleTemplate(event, item._id)}
                                                            value={item.templ_subject} name="templ_subject" disabled={access_level == 1 ? true : false}></textarea></td>
                                                        <td><textarea className="form-control" rows="1" onChange={(event) => onChangeMultipleTemplate(event, item._id)}
                                                            value={item.templ_comments} name="templ_comments" disabled={access_level == 1 ? true : false}></textarea></td>
                                                        <td className='d-flex align-items-center justify-content-center' style={{ height: "32px", gap: "5px" }}>

                                                            <NavLink
                                                                style={{ maxWidth: "50%", padding: "1px" }}
                                                                to={`/settings/mail_template_details/${item._id}`}
                                                                item={item}
                                                            >
                                                                View
                                                            </NavLink>
                                                            <input
                                                                name="showInPromotionList"
                                                                onChange={(event) => onChangeMultipleTemplate(event, item._id)}
                                                                type="checkbox" checked={item?.showInPromotionList ? true : false}
                                                            // onChange={handleOnChange}
                                                            />
                                                            {access_level == 3 && <i
                                                                className="btn fa fa-trash"
                                                                style={{ color: "rgb(228, 86, 110)", maxWidth: "30px", float: "Right", padding: "0px" }}
                                                                onClick={() => {
                                                                    delete_toggle();
                                                                    set_del_id(item._id);
                                                                }}
                                                            >
                                                            </i>}
                                                            {/* <style>{`
                                                        .dropdown-menu .dropdown-item{padding:6px 10px !important}
                                                        .dropdown-menu{min-width:150px !important}
                                                        `}</style> */}

                                                            <div
                                                                // type="button"
                                                                ref={popoverRef}
                                                                id={`Popover-${index}`}
                                                                style={{ cursor: "pointer", padding: "3px" }}
                                                                onClick={() => { toggleDropdown(item._id, templates, "button") }}
                                                            >
                                                                <TiArrowSortedDown style={{ fontSize: "18px" }} />
                                                            </div>
                                                            <Popover
                                                                isOpen={popoverOpen && openRow == item._id}
                                                                target={`Popover-${index}`}
                                                                toggle={() => { toggleDropdown(item._id, templates, "popup") }}
                                                                placement="bottom"
                                                                trigger="legacy"
                                                            >
                                                                <PopoverBody style={{ padding: "8px" }}>
                                                                    {catgoryData && catgoryData.length > 0 && catgoryData.map((currItem) => {
                                                                        let getValue = item.temlate_category.length > 0 && item.temlate_category.findIndex((curElem) => curElem.hasOwnProperty(`${currItem.title_name}`))
                                                                        console.log(`${item.templ_name}---${currItem.title_name}-${item.temlate_category[getValue]}`, "test values---")
                                                                        return (
                                                                            <div
                                                                                key={currItem.title_name}
                                                                                toggle={false}
                                                                                style={{ padding: "8px", outline: "none" }} >
                                                                                <div className='d-flex align-items-center'>
                                                                                    <input
                                                                                        style={{ height: "17px", width: "17px" }}
                                                                                        type="checkbox"
                                                                                        id={`${currItem.title_name}_${item._id}`}
                                                                                        name={`${currItem.title_name}_${item._id}`}
                                                                                        // checked={selectedOptions[item.id]?.includes(option.value) ||false}
                                                                                        onChange={(event) => {
                                                                                            let value = event.target.checked;
                                                                                            handleCheckboxChange(item._id, currItem.title_name, value)
                                                                                        }}
                                                                                    />
                                                                                    <label className='ml-2 mb-0 text-capitalize' for={`${currItem.title_name}_${item._id}`}> {currItem.title_name}</label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </PopoverBody>
                                                            </Popover>

                                                            {/* <Dropdown
                                                                isOpen={openRow == item._id}
                                                                toggle={() => { toggleDropdown(item._id, templates) }}
                                                            >
                                                                <DropdownToggle style={{ padding: "0px", lineHeight: "0px" }} ><TiArrowSortedDown /></DropdownToggle>
                                                                <DropdownMenu sty>
                                                                    {catgoryData && catgoryData.length > 0 && catgoryData.map((currItem) => (
                                                                        <DropdownItem
                                                                            key={currItem.title_name}
                                                                            toggle={false}
                                                                            style={{ padding: "8px 16px", outline: "none" }} >
                                                                            <div className='d-flex align-items-center'>
                                                                                <input
                                                                                    style={{ height: "17px", width: "17px" }}
                                                                                    type="checkbox"
                                                                                    id={`${currItem.title_name}_${item._id}`}
                                                                                    name={`${currItem.title_name}_${item._id}`}
                                                                                    // checked={selectedOptions[item.id]?.includes(option.value) ||false}
                                                                                    onChange={(event) => {
                                                                                        let value = event.target.checked;
                                                                                        let setObj = { [`${currItem.title_name}`]: value }
                                                                                        handleCheckboxChange(item._id, currItem.title_name, value)
                                                                                    }}
                                                                                />
                                                                                <label className='ml-2 mb-0 text-capitalize' for={`${currItem.title_name}_${item._id}`}> {currItem.title_name}</label>
                                                                            </div>
                                                                        </DropdownItem>
                                                                    ))}
                                                                </DropdownMenu>
                                                            </Dropdown> */}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                    : <div className='d-flex align-items-center justify-content-center' style={{ height: "200px" }}>
                                        <h6 style={{ textAlign: "center" }}>No template added!</h6>
                                    </div>
                            }

                        </>
                    }

                    {
                        template_data.filter(item => template_type_check == item.templ_user_type).length > 0 && <div style={{ textAlign: "center" }}>
                            <button className="btn btn-primary btn-xs pull-right" style={{ marginTop: "15px" }} disabled={btnloader}
                                onClick={toggleupdateModal}>Update</button>
                        </div>
                    }
                </Fragment> :
                <Fragment>
                    <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                        <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                            <DisappearedLoading size="medium" color="#514F4E" />
                        </div>
                    </div></Fragment>

        )
    }

    let tabStyle = {
        width: "fit-content",
        backgroundColor: "#327EBC",
        color: "#fff",
        padding: "7px 10px",
        borderRadius: "0.2rem",
        cursor: "pointer",
        fontSize: "13px"
    };


    const resetData = () => {
        setCatError(false);
        toggleCatModal();
        setCateName('');
    }


    const fetchCatData = async () => {
        let postdata = { auto_reminder: false };
        client.post(api.fetch_template_category, postdata, async function (error, response) {
            // console.log(response, "response");
            if (!error) {
                if (!response.error) {
                    setCategoryData(response.result);
                } else {
                    toast.error(response.message);
                }
            } else {
                toast.error("Internal error occured. Please contact support");
            }
        });
    }

    useEffect(() => {
        fetchCatData();
    }, [])





    // setDropdownOpen((prevState) => !prevState});

    return (
        <Fragment>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-xl-12 xl-100">
                        <div className="card">
                            <div className="card-body" style={{ padding: "1rem" }}>
                                {/* {access_level != 1 && <Button className="mb-3" outline color="primary" onClick={toggle2}><i className="fa fa-plus" style={{ margin: "0 7px 0 0" }}></i>Add New Template</Button>} */}

                                <div className='d-flex align-items-center justify-content-between mb-2'>
                                    <Nav tabs className="nav-pills nav-light">
                                        {/* <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                            <NavLinkTab className={activeTab == '1' ? 'active nav-link' : ''} onClick={() => setActiveTab('1')} value='1'>
                                                Customers ({template_data ? template_data.filter(function (element) {
                                                    return element.templ_user_type === 'customer';
                                                }).length : 0})
                                            </NavLinkTab>
                                        </NavItem> */}

                                        <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                            <NavLinkTab className={activeTab == '2' ? 'active nav-link' : ''} onClick={() => setActiveTab('2')} value='2'>
                                                Merchants ({template_data ? template_data.filter(function (element) {
                                                    return element.templ_user_type === 'merchant';
                                                }).length : 0})
                                            </NavLinkTab>
                                        </NavItem>

                                        <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                            <NavLinkTab className={activeTab == '3' ? 'active nav-link' : ''} onClick={() => setActiveTab('3')} value='3'>
                                                Others ({template_data ? template_data.filter(function (element) {
                                                    return element.templ_user_type === 'others';
                                                }).length : 0})
                                            </NavLinkTab>
                                        </NavItem>

                                    </Nav>
                                    <div className='ml-auto d-flex align-items-center'>
                                        <div className='mr-2 position-relative'>
                                            <style>
                                                {`input::placeholder {color: gray;}`}</style>
                                            <input
                                                name='search'
                                                className='search_field'
                                                placeholder='Search...'
                                                style={{
                                                    border: "#dfe8f1 solid 1px",
                                                    borderRadius: "3px",
                                                    padding: "10px",
                                                    fontSize: "0.75rem",
                                                    outlineColor: '#dfe8f1',
                                                    minWidth: "250px"
                                                }}
                                                value={searchData}
                                                onChange={(event) => { setSearchData(event.target.value) }}
                                            />
                                            {searchData.length > 0 && <span
                                                style={{ position: "absolute", right: "7px", top: "10px", cursor: "pointer" }}
                                                onClick={() => { setSearchData('') }}
                                            >
                                                <MdClose style={{ fontSize: "17px" }} />
                                            </span>}
                                        </div>
                                        <div className=' p-1 border rounded'>
                                            <div className='d-flex align-items-center'>
                                                <span
                                                    style={{ padding: "0.4rem 0.5rem", backgroundColor: viewType === "listView" ? "#327ebc " : "#fff" }}
                                                    onClick={() => { setViewType('listView') }}
                                                >
                                                    <FaList
                                                        style={{
                                                            color: viewType === "listView" ? "#fff" : "grey",
                                                            backgroundColor: viewType === "listView" ? "327ebc" : "#fff",
                                                            width: '18px',
                                                            height: '18px',
                                                            verticalAlign: "middle"
                                                        }}
                                                    />
                                                </span>
                                                <span
                                                    style={{ padding: "0.4rem 0.5rem", backgroundColor: viewType === "gridView" ? "#327ebc " : "#fff" }}
                                                    onClick={() => { setViewType('gridView') }}
                                                >
                                                    <TfiLayoutGrid3Alt
                                                        style={{
                                                            color: viewType === "gridView" ? "#fff" : "grey",
                                                            backgroundColor: viewType === "gridView" ? "327ebc" : "#fff",
                                                            width: '18px',
                                                            height: '18px',
                                                            verticalAlign: "middle"
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='w-100 mb-3 d-flex align-items-center' style={{ flexWrap: "wrap" }}>
                                    <div className='mt-2' style={tabStyle} onClick={() => toggleCatModal()}>Add Group</div>
                                    <div
                                        className='mx-2 mt-2'
                                        style={{
                                            ...tabStyle,
                                            padding: "7px 10px",
                                            backgroundColor: selectedCategory?.title_name.toLowerCase() == 'all' ? '#327EBC' : "#fff",
                                            color: selectedCategory?.title_name.toLowerCase() == 'all' ? "#fff" : "#000000",
                                            border: selectedCategory?.title_name.toLowerCase() == 'all' ? "1px solid #327EBC" : "1px solid rgb(35, 39, 43)"
                                        }}
                                        onClick={() => { setSelectedCategory({ title_name: 'all' }) }}
                                    >All</div>
                                    {catgoryData && catgoryData.length > 0 && catgoryData.map((currItem) => {
                                        return (
                                            <div
                                                className='mr-2 mt-2 text-capitalize'
                                                style={{
                                                    ...tabStyle,
                                                    backgroundColor: selectedCategory?.title_name.toLowerCase() == currItem.title_name.toLowerCase() ? '#327EBC' : "#fff",
                                                    color: selectedCategory?.title_name.toLowerCase() == currItem.title_name.toLowerCase() ? "#fff" : "#000000",
                                                    border: selectedCategory?.title_name.toLowerCase() == currItem.title_name.toLowerCase() ? "1px solid #327EBC" : "1px solid rgb(35, 39, 43)"
                                                }}
                                                onClick={() => { setSelectedCategory({ title_name: currItem.title_name }) }}
                                            >
                                                {currItem.title_name}
                                            </div>
                                        )
                                    })}
                                </div>


                                {/* <div className='my-3' style={{ border: "1px solid red" }}>
                                    <Dropdown isOpen={dropdownOpen} toggle={togglePopUp}>
                                        <DropdownToggle caret></DropdownToggle>
                                        <DropdownMenu>
                                            {catgoryData && catgoryData.length > 0 && catgoryData.map((currItem) => (
                                                <DropdownItem key={currItem.title_name} toggle={false} style={{ padding: "8px 16px", outline: "none" }} >
                                                    <div className='d-flex align-items-center'>
                                                        <input
                                                            style={{ height: "18px", width: "18px" }}
                                                            type="checkbox"
                                                            id={`${currItem.title_name}`}
                                                        // checked={selectedOptions[item.id]?.includes(option.value) ||false}
                                                        // onChange={() => handleCheckboxChange(item.id, option.value)}
                                                        />
                                                        <label className='ml-2 mb-0 text-capitalize' for={`${currItem.title_name}`}> {currItem.title_name}</label>
                                                    </div>
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div> */}

                                {
                                    template_data ?

                                        <TabContent activeTab={activeTab} >
                                            {/* <TabPane tabId="1">
                                                {rendertable('customer')}
                                            </TabPane> */}

                                            <TabPane tabId="2">
                                                {rendertable('merchant')}
                                            </TabPane>

                                            <TabPane tabId="3">
                                                {rendertable('others')}
                                            </TabPane>

                                        </TabContent>
                                        : <div className="loader-box" style={{ height: "100vh", width: "100wh" }}>
                                            <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                                                <DisappearedLoading size="medium" color="#514F4E" />
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {renderUpdateModal()}

            <Modal isOpen={props.modal} toggle={props.toggle} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" onSubmit={handleSubmit}>
                    <ModalHeader toggle={props.toggle}>Add New Template</ModalHeader>
                    <ModalBody>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label" >Unique Id</label>
                            <div className="col-sm-9">
                                <input className="form-control" id="mail_unique_id" type="text" placeholder="Number" name="mail_unique_id" value={mailUniqueId}
                                    onChange={e => {

                                        setMailUniqueId(e.target.value);

                                        if (e.target.value == '') {
                                            setGetError(true);
                                            setErrorsMsg('Required!');
                                        } else if (isNaN(e.target.value)) {
                                            setGetError(true);
                                            setErrorsMsg('Only numbers are allowed!');
                                        } else if (e.target.value.length !== 6) {
                                            setGetError(true);
                                            setErrorsMsg('Only 6 digits are allowed!');
                                        } else {
                                            setGetError(false);
                                            setErrorsMsg('');
                                        }
                                    }}
                                />
                                {
                                    getError ?
                                        <small className='text-danger'> {errorsMsg} </small>
                                        : null
                                }
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">User</label>
                            <div className="col-sm-9">
                                <select className="form-control" name="templ_user_type" id="user_type" value={templ_user_type}
                                    onChange={e => set_templ_user_type(e.target.value)} >
                                    <option value="" >Select Option</option>
                                    {/* <option value="customer" >Customer</option> */}
                                    <option value="merchant" >Merchant</option>
                                    <option value="others" >Others</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label" >Name</label>
                            <div className="col-sm-9">
                                <input className="form-control" id="inputEmail3" type="text" placeholder="Name" name="templ_name" value={templ_name}
                                    onChange={e => set_templ_Name(e.target.value)} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label" >Test Mail</label>
                            <div className="col-sm-9">
                                <input className="form-control" id="Test_mail" type="text" placeholder="Test Mail" name="templ_test_mail" value={templ_test_mail}
                                    onChange={e => set_templ_test_mail(e.target.value)} />
                            </div>
                        </div>

                        {/* <div className="form-group row">
                            <label className="col-sm-3 col-form-label" >From</label>
                            <div className="col-sm-9">
                                <input className="form-control" id="From" type="text" placeholder="From" name="templ_from" value={templ_from}
                                    onChange={e => set_templ_from(e.target.value)} />
                            </div>
                        </div> */}

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label" >Subject</label>
                            <div className="col-sm-9">
                                <input className="form-control" id="Subject" type="text" placeholder="Subject" name="templ_subject" value={templ_subject}
                                    onChange={e => set_templ_subject(e.target.value)} />
                            </div>
                        </div>

                        {/* <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Layout</label>
                            <div className="col-sm-9">
                                <textarea className="form-control" id="layout" rows="2" name="templ_layout" value={templ_layout}
                                    onChange={e => set_templ_layout(e.target.value)}></textarea>
                            </div>
                        </div> */}

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label" >Comments:</label>
                            <div className="col-sm-9">

                                <textarea className="form-control" id="comments" rows="2" name="templ_comments" value={templ_comments}
                                    onChange={e => set_templ_comments(e.target.value)} ></textarea>
                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" >Save</Button>
                        <Button color="default" onClick={props.toggle}>Cancel</Button>
                    </ModalFooter>
                </form>
            </Modal>

            <Modal isOpen={delete_modal} toggle={delete_toggle} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" >
                    <ModalHeader toggle={delete_toggle}>Delete Template</ModalHeader>
                    <ModalBody>
                        <h6>Do you really want to delete this Template ?</h6>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={delete_toggle}>No</Button>
                        <Button color="primary" type="submit" onClick={(e) => { handleDeleteSubmit(e, del_templ_id) }} disabled={btnloader}>Yes</Button>
                    </ModalFooter>
                </form>
            </Modal>

            {catModal && <AddTemplateCategoryModal
                isOpen={catModal}
                toggle={toggleCatModal}
                catgoryData={catgoryData}
                fetchCatData={fetchCatData}
            />}
        </Fragment >
    );

}

export default EmailTemplates;