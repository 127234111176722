import React, { useState, useRef, useEffect } from "react";
import "./DropDown.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { IoCaretDownSharp, IoCaretUp } from "react-icons/io5";
import { RiLineHeight } from "react-icons/ri";



const Dropdown = ({ options, label, onSelect, dropDownStyle, type, icon }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(options[0]);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => setIsOpen((prev) => !prev);
    const handleSelect = (option) => {
        setSelected(option);
        onSelect(option);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <>
            <div className="dropdown-container" ref={dropdownRef}>
                <div
                    className="button_style"
                    onClick={() => { toggleDropdown() }}
                >
                    {icon ? icon : <>{selected?.label}</>}  {isOpen ? < IoCaretUp style={{ fontSize: "11px", marginLeft: "0rem" }} /> : < IoCaretDownSharp style={{ fontSize: "11px", marginLeft: "0rem" }} />}
                </div>
                {isOpen && <div className="dropdown_menu" style={dropDownStyle}>
                    {options.map((option, index) => (
                        <div
                            key={index}
                            style={option.style || {}}
                            className="dropdown_item d-fles align-items-center"
                            onClick={() => handleSelect(option)}
                        >
                            {option.Icon ? option.Icon : null}  {option.label ? option.label : ""}
                        </div>
                    ))}
                </div>}
            </div>
        </>
    );
};

export default Dropdown;
