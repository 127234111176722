import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLayoutData } from "../../../../../store/templateEditorData";

const TextElement = ({ elemContent }) => {
    const { style, content, id } = elemContent;
    const dispatch = useDispatch();
    const textRef = useRef(null);

    const templateLayoutData = useSelector((state) => state.emailTemplateEditorData.templateLayoutData);

    const handleInput = () => {
        const newText = textRef.current?.textContent.trim() || "";

        if (newText !== content) {
            const updatedLayout = templateLayoutData.map((item) =>
                item.id === id ? { ...item, content: newText } : item
            );

            dispatch(setLayoutData(updatedLayout));
        }
    };

    return (
        <div
            ref={textRef}
            contentEditable
            suppressContentEditableWarning={true}
            onBlur={handleInput}
            style={style}
        >
            {content || "Enter text here..."}
        </div>
    );
};

export default TextElement;





// import React, { useState, useEffect, useRef } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { setLayoutData, setSelectedElement, setOuterData } from "../../../../../store/templateEditorData";

// const TextElement = (layoutInfo) => {

//     const { style, content } = layoutInfo.elemContent;
//     const dispatch = useDispatch();

//     const { templateLayoutData, selectedElementData, outderData } = useSelector((state) => state.emailTemplateEditorData);

//     const handleInput = (e) => {

//         const textData = e.target.textContent;

//         if (textData !== content) {

//             let abc = { ...selectedElementData, content: textData };
//             let setData = [...templateLayoutData];
//             let findIndex = setData.findIndex((currElem) => currElem.id == selectedElementData.id);
//             if (findIndex != -1) {
//                 setData[findIndex] = abc
//             };
//             dispatch(setLayoutData(setData));
//         }
//     };


//     return (
//         <>
//             <div
//                 contentEditable
//                 suppressContentEditableWarning={true}
//                 onBlur={handleInput}
//                 style={style}
//             >
//                 {content}
//             </div>
//         </>
//     )
// }

// export default TextElement