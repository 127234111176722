import React, { useState, useRef, useEffect } from "react";
import { SketchPicker, CompactPicker } from "react-color";

const ColorPicker = ({ icon, handleOnChange, colorValue, popUpStyle }) => {

    const [pickerState, setPickerState] = useState({ bgColorPicker: false });
    const bgColorPickerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (bgColorPickerRef.current && !bgColorPickerRef.current.contains(event.target)) {
                setPickerState((prev) => ({ ...prev, bgColorPicker: false }));
            }
        };

        if (pickerState.bgColorPicker) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [pickerState.bgColorPicker]);

    const presetColors = [
        "#000000", "#808080", "#C0C0C0", "#FFFFFF", "#FF0000", "#800000",
        "#FFFF00", "#808000", "#00FF00", "#008000", "#00FFFF", "#008080",
        "#0000FF", "#000080", "#FF00FF", "#800080", "#B0C4DE", "#4682B4",
        "#D2691E", "#8B4513", "#FF4500", "#FFD700", "#32CD32", "#7FFFD4",
        "#1E90FF", "#4B0082", "#FF1493", "#DC143C", "#A52A2A", "#5b0f00",
        "#8A2BE2", "#9932CC", "#9400D3", "#BA55D3", "#9370DB", "#FF69B4",
        "#CD5C5C", "#F08080", "#FA8072", "#E9967A", "#FF7F50", "#FF6347",
        "#FFA07A", "#FFDAB9", "#EEE8AA", "#F0E68C", "#BDB76B", "#556B2F",
        "#6B8E23", "#9ACD32"
    ];

    return (
        <div className="position-relative">
            <div
                className="element"
                onClick={() => setPickerState(prev => ({ ...prev, bgColorPicker: !prev.bgColorPicker }))}
            >
                {icon}
            </div>

            {pickerState.bgColorPicker && (
                <div
                    ref={bgColorPickerRef}
                    style={{
                        position: "absolute",
                        zIndex: 9999,
                        background: "#fff",
                        padding: "0px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                        ...popUpStyle
                    }}
                >
                    <SketchPicker
                        color={colorValue}
                        onChange={handleOnChange}
                        presetColors={presetColors}
                    />
                </div>
            )}
        </div>
    );
};

export default ColorPicker;
